const gameUiTexts = {
	subtitle: 'Spillet om virksomhedsøkonomi',
	start: 'Start',
	manual: 'Lærervejledning',
	viewVideo: 'Se video',
	loadingGame: 'Henter spil ...',
	reset: 'Reset',
	budget: 'Budget',
	budgeted: 'Budgetteret',
	budgetThisQuarter: 'Budget - dette kvartal',
	actualBudget: 'Realiseret',
	turnover: 'Omsætning',
	expenses: 'Udgifter',
	profit: 'Overskud',
	quarter: 'Kvartal',
	kr: 'kr',
	ok: 'OK',
	go: 'Go!',
	startNewGame: 'Nyt spil',
	currentSupplier: 'Nuværende leverandør',

	betweenRoundsText: 'I laver og sælger T-shirts på livet løs.',
	
	optionCannotBeSelectedFeedback: {
		minBudgetStat: {
			turnover: `Jeres omsætning for sidste kvartal var kun %value% kr.
				<br /><br />I skal have en omsætning på %required% kr for at få dette lån.`
		}
	},

	maxOptionsSelected: 'Du kan ikke vælge flere handlinger.<br /><br />Vælg en anden handling fra, hvis du gerne vil vælge denne handling.',
	notEnoughtActionsSelected: `Du har kun valgt %selectedActions% af %availableActions% mulige handlinger.
		<br /><br />Er du sikker på, du vil fortsætte?`,
	yesContinue: 'Ja, fortsæt',
	noCancel: 'Nej, afbryd',
	cookieText1: `Vi vil gerne gemme dine valgte handlinger og din progression i spillet i en cookie.
		<br /><br />Du kan ikke spille uden denne cookie. Tryk "JA" for at acceptere den.`,
	cookieText2: `<br />Cookien bliver gemt i et år.
		<br /><br />Trykke "NEJ" for at slette cookien og trække dit samtykke tilbage.`,
	cookieSeeMore: 'Se mere',
	cookieSeeLess: 'Se mindre',
	yes: 'Ja',
	no: 'Nej'
};
const statsUiTexts = {
	loginTitle: 'Statistik',
	email: 'e-mail',
	password: 'password',
	login: 'Log ind',
	logout: 'Log ud',
	unknownFejl: 'Fejl: ukendt fejl',
	someFieldsMissing: 'Fejl: Husk at udfylde alle felter',
	invalidEmail: 'Fejl: Ugyldig e-mail',
	invalidPassword: 'Fejl: Forkert password',
	userNotFound: 'Fejl: Ukendt bruger',
	statsTitle: 'Statistik for Iværksætterøkonomispil',
	gamesStarted: 'Antal spil startet',
	gamesFinished: 'Antal spil afsluttet',
	gamesOngoing: 'Antal spil igangværende',
	players: 'Spillere',
	playerId: 'Id',
	playDate: 'Dato',
	lastRound: 'Runder spillet',
	action: 'Handling',
	salesPrice: 'Salgspris',
	stock: 'Lager',
	purchase: 'Indkøb',
	prodCap: 'Prod.-kapacitet',
	buyingCustomers: 'Kunder der køber',
	turnover: 'Omsætning',
	expenses: 'Udgifter',
	profit: 'Overskud',
	liquidity: 'Likviditet',
	download: 'Download data'
	
};

module.exports = {
	gameUiTexts,
	statsUiTexts
};