import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Select from '../select/select';
import './area-action-option-select.scss';

const AreaActionOptionSelect = (props) => {
	/* Props */
	const {
		isSelected, 
		isConfirmed, 
		actionId,
		actionOptionIdOfActiveDropdown,
		option, 
		currentStats,
		setActionOptionIdOfActiveDropdown,
		handleSelectActionOption, 
		handleDeselectActionOption,
	} = props;
	
	/* Get min / max / current values */
	let options = [];
	if (actionId === 'tshirt-supplier' && option.id === currentStats[option.modifiesStatId2]) {
		options.push(0);
	}
	for (let i = option.minVal; i <= option.maxVal; i += option.stepSize) {
		options.push(i);
	}

	/* Get current index of selected value */
	let selectedIndex = (currentStats[option.statId] !== null
		? options.indexOf(currentStats[option.statId])
		: options.indexOf(currentStats[option.modifiesStatId])
	);
	if (selectedIndex < 0) selectedIndex = 0;
	
	/**
	 * Player clicks action option without selecting a value
	 */
	const handleSelectOption = () => {
		if (isSelected) {
			/* Deselect option */
			handleDeselectActionOption();
			autoCloseDropdown();
		} else {
			if (setActionOptionIdOfActiveDropdown) {
				/* Auto-open dropdown */
				setActionOptionIdOfActiveDropdown(option.id);
			}
		}
	};

	/**
	 * Player select specific value for option
	 * @param {number} optionValue 
	 */
	const handleSelectOptionValue = (optionValue) => {
		if (actionId === 'offer-campaign') {
			/* Adjust price */
			if (
				optionValue !== currentStats[option.statId] &&
				optionValue !== currentStats[option.modifiesStatId]
			) {
				if (isSelected) {
					/* Update value */
					handleSelectActionOption(option.id, optionValue);
				} else {
					/* New value selected */
					handleSelectActionOption(option.id, optionValue);
				}
			}	else {
				/* Same as original value => deselect */
				if (isSelected) {
					handleSelectActionOption(option.id);
				}
			}	
		}

		if (actionId === 'tshirt-supplier') {
			/* Adjust supplier and/or purchase amount */
			if (currentStats[option.modifiesStatId2] === option.id) {
				/* Current supplier */
				if (optionValue === currentStats[option.modifiesStatId]) {
					/* Current base value -> deselect option */
					handleDeselectActionOption();
				} else if (optionValue === currentStats[option.statId]) {
					/* Current selected value -> ignore */
				} else {
					/* New selected value */
					handleSelectActionOption(option.id, optionValue);
				}
			} else {
				/* New supplier */
				handleSelectActionOption(option.id, optionValue);
			}
		}

		/* Auto-close drop down */
		autoCloseDropdown();
	};

	/**
	 * Auto-close drop down
	 */
	const autoCloseDropdown = () => {
		if (setActionOptionIdOfActiveDropdown) {
			setActionOptionIdOfActiveDropdown(null);
		}
	};

	let title = option.title;
	if (actionId === 'tshirt-supplier' && option.id === currentStats[option.modifiesStatId2]) {
		title = option.title2;
	}


	return (
		<div 
			key={option.id} 
			className={'AreaActionOptionSelect' + 
				(isSelected ? ' selected' : '') + (isConfirmed ? ' confirmed' : '')}
			onClick={() => {handleSelectOption();}}
			onMouseLeave={() => {autoCloseDropdown();}}
		>
			<div className="AreaActionOptionSelect-content">
				<div className={'AreaActionOptionSelect-optionImage ' + actionId + ' ' + option.id} />
				<div className="AreaActionOptionSelect-optionStatus">
					<div className={'AreaActionOptionSelect-checkMark' + 
						(isConfirmed ? ' confirmed' : ' unconfirmed')}/>
				</div>
				<div className="AreaActionOptionSelect-info">
					<div className="AreaActionOptionSelect-optionTitle">
						<span>{title}</span>
					</div>
					<div className="AreaActionOptionSelect-optionDescription">
						{renderMarkdown(option.description)}
					</div>
				</div>
				<div 
					className="AreaActionOptionSelect-select"
					onClick={(e) => {e.stopPropagation();}}
				>
					<Select 
						type = {''}
						selectedIndex={selectedIndex} 
						unit={option.unit ? option.unit : null}
						actionOptionId={option.id}
						actionOptionIdOfActiveDropdown={actionOptionIdOfActiveDropdown}
						options={options} 
						onSelect={handleSelectOptionValue}
						setActionOptionIdOfActiveDropdown={setActionOptionIdOfActiveDropdown}
					/>
				</div>
				{(actionId === 'tshirt-supplier' && option.id === currentStats[option.modifiesStatId2]) && 
					<div className='AreaActionOptionSelect-label'><span>{gameUiTexts.currentSupplier}</span></div>
				}
			</div>
		</div>
	);
};

AreaActionOptionSelect.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	isConfirmed: PropTypes.bool.isRequired,
	actionId: PropTypes.string.isRequired,
	actionOptionIdOfActiveDropdown: PropTypes.string,
	option: PropTypes.object.isRequired,
	currentStats: PropTypes.object.isRequired,
	setActionOptionIdOfActiveDropdown: PropTypes.func,
	handleSelectActionOption: PropTypes.func.isRequired,
	handleDeselectActionOption: PropTypes.func.isRequired,
	
};

export default AreaActionOptionSelect;