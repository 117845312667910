import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import './area-action-option-box.scss';

const AreaActionOptionBox = ({option, price, isSelected, isConfirmed, handleSelectActionOption}) => {
	return (
		<div 
			className={'AreaActionOptionBox' + 
				(isSelected ? ' selected' : '') + (isConfirmed ? ' confirmed' : '')}
			onClick={() => {handleSelectActionOption(option.id);}}
		>
			<div className="AreaActionOptionBox-optionStatus">
				<div className={'AreaActionOptionBox-checkMark' + 
					(isConfirmed ? ' confirmed' : ' unconfirmed')}/>
			</div>
			<div className='AreaActionOptionBox-optionTitle'>
				<span>{option.title}</span>
			</div>
			<div className='AreaActionOptionBox-optionDescription'>
				<span>{option.description}</span>
			</div>
			<div className='AreaActionOptionBox-content'>
				<div className={'AreaActionOptionBox-image ' + (option.id ? option.id : '')}/>
				{price && <div className="AreaActionOptionBox-price"><span>{price} {gameUiTexts.kr}</span></div>}
			</div>
		</div>
	);
};

AreaActionOptionBox.propTypes = {
	option: PropTypes.object.isRequired,
	price: PropTypes.any,
	isSelected: PropTypes.bool.isRequired,
	isConfirmed: PropTypes.bool.isRequired,
	handleSelectActionOption: PropTypes.func.isRequired
};

export default AreaActionOptionBox;