/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {getReportText} from 'helpers/reports-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './area-page-report.scss';

const AreaPageReport = ({gameData}) => {
	const prevRound = (gameData.rounds && gameData.rounds.length > 0
		? gameData.rounds[gameData.rounds.length - 1]
		: null
	);
	const report = (prevRound && prevRound.report
		? getReportText(prevRound.report)
		: ''
	);

	return (
		<div className="AreaPageReport">
			{report.length > 0 && renderMarkdown(report)}
		</div>
	);
};


AreaPageReport.propTypes = {
	gameData: PropTypes.object.isRequired
};


export default AreaPageReport;