import React from 'react';
import PropTypes from 'prop-types';
import {roundsData} from 'data/rounds-data';
import './round-number.scss';

const RoundNumber = ({gameData}) => {
	const indexOfCurrentRound = roundsData.findIndex((r) => {
		return r.roundNumber === gameData.roundNumber;
	});

	return (
		<div className="RoundNumber">
			<div className={'RoundNumber-line blue round-' + (indexOfCurrentRound + 1)} />
			<div className={'RoundNumber-line grey round-' + (indexOfCurrentRound + 1)} />
			{roundsData.map((round) => {
				const roundStatus = (round.roundNumber < gameData.roundNumber 
					? 'completed' 
					: (round.roundNumber === gameData.roundNumber ? 'current' : null)
				);
				return (
					<div 
						key={round.roundNumber} 
						className={'RoundNumber-round' + (roundStatus ? ' ' + roundStatus : '')}
					>
						<span>{round.roundNumber}</span>
					</div>
				);
			})}
		</div>
	);
};


RoundNumber.propTypes = {
	gameData: PropTypes.object.isRequired,
};


export default RoundNumber;