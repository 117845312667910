
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {gameUiTexts} from 'data/ui-texts';
import {roundsData} from 'data/rounds-data';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './store-front.scss';
import PopupVideo from 'components/ui/popup-video/popup-video';

const StoreFront = ({isEndingRound, animateEnterShop, newRoundNumber, handleStartGame, setCookiePopupType}) => {

	/* Video popup */
	const [showVideoPopup, setShowVideoPopup] = useState(false);

	return (
		<div className={'StoreFront' + (animateEnterShop ? ' enterShop' : '')}>
			<div className='StoreFront-store' />
			<div className='StoreFront-characters' />
			{!isEndingRound &&
				<div className='StoreFront-logos'>
					<div className='StoreFront-logoGameLab' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
					<div className='StoreFront-fundLogo'/>
				</div>
			}

			{!isEndingRound && <div className="StoreFront-logo">
				<Logo />
			</div>}
			{!isEndingRound && <div className="StoreFront-subtitle">
				<span>{gameUiTexts.subtitle}</span>
			</div>}
			{!isEndingRound && <div className="StoreFront-startBtn">
				<Button 
					text={gameUiTexts.start}
					classes={['blue', 'start']}
					onClick={() => {handleStartGame();}}
				/>
			</div>}
			{!isEndingRound && <div className="StoreFront-manualBtn">
				<Button 
					text={gameUiTexts.manual}
					type="download"
					classes={['blue', 'start']}
					href="/files/manual.pdf"
				/>
			</div>}
			{!isEndingRound && <div className="StoreFront-videoBtn">
				<Button 
					text={gameUiTexts.viewVideo}
					classes={['blue', 'video']}
					onClick={() => {setShowVideoPopup(true);}}
				/>
			</div>}
			{!isEndingRound && <div 
				className="StoreFront-toggleCookiePopup" 
				onClick={() => {setCookiePopupType('default');}}
			/>}

			{isEndingRound && <div className="StoreFront-roundAnimation">
				<div className="StoreFront-rounds">
					<div className="StoreFront-line" />
					{roundsData.map((round) => {
						const roundStatus = (round.roundNumber < newRoundNumber 
							? 'completed' 
							: (round.roundNumber === newRoundNumber ? 'new' : null)
						);
						return (
							<div 
								key={round.roundNumber} 
								className={'StoreFront-round' + (roundStatus ? ' ' + roundStatus : '')}
							>
								<span>{round.title}</span>
							</div>
						);
					})}
				</div>
				<div className="StoreFront-text">
					{renderMarkdown(gameUiTexts.betweenRoundsText)}
				</div>
				<div className="StoreFront-animation" />
				<div className="StoreFront-timer">
					<div 
						className={'StoreFront-track'} 
						style={{'--duration': appConfig.newRoundAnimationDurationSec + 's'}} 
					/>
				</div>
			</div>}

			{/* Video popup */}
			{showVideoPopup && <PopupVideo setShowVideoPopup={setShowVideoPopup} />}

		</div>
	);
};

StoreFront.propTypes = {
	isEndingRound: PropTypes.bool.isRequired,
	animateEnterShop: PropTypes.bool.isRequired,
	newRoundNumber: PropTypes.number,
	handleStartGame: PropTypes.func.isRequired,
	setCookiePopupType: PropTypes.func.isRequired,
};

export default StoreFront;