import appConfig from 'config/app.config';
import {reportsData} from 'data/reports-data';
import {addThousandSeparator} from 'helpers/math-helper';
import {sortArrayByProperty} from './array-helper';
import {getInitialStats, calculateCurrentStats, calculateBudget} from 'helpers/stat-and-budget-helpers';

/**
 * Get report text
 * @param {array} reportSnippets 
 * @returns 
 */
export function getReportText(reportSnippets) {
	let report = '';

	if (reportSnippets) {
		reportSnippets.forEach((snippet) => {
			const snippetData = reportsData.find((s) => {return s.id === snippet.snippetId;});
			if (snippetData) {
				/* Get default text */
				let text = JSON.parse(JSON.stringify(snippetData.text));

				/* Get extra text */
				if (snippet.addText2 === true && snippetData.text2) {
					text += JSON.parse(JSON.stringify(snippetData.text2));
				}

				/* Replace dynamic parts */
				for (const property in snippet.snippetData) {
					text = text.replace('%' + property + '%', snippet.snippetData[property]);
				}
				
				/* Add to report */
				report += (report.length > 0 ? '<br /><br />' : '') + text;
			}
		});
	}

	return report;
}

/**
 * Generate report for round
 * @param {array} gameRounds 
 * @param {array} bankruptcies 
 * @returns 
 */
export function generateReport(gameRounds, bankruptcies) {
	/* Sort report snippets by priority */
	const reportSnippets = sortArrayByProperty(reportsData, 'priority', 'ASC');

	/* Loop over snippets, select snippets until point sum is max */
	let selectedReportSnippets = [];
	let points = 0;
	reportSnippets.forEach((snippet) => {
		/* Return if max points reached */
		if (points >= appConfig.reportMaxPoints) return;

		/* Check if conditions are met */
		const {conditionsAreMet, snippetData} = 
			checkIfReportConditionsAreMet(snippet.id, snippet.conditions, gameRounds, bankruptcies);

		
		if (conditionsAreMet && (points + snippet.points <= appConfig.reportMaxPoints)) {
			/* Add to report */
			selectedReportSnippets.push({snippetId: snippet.id, order: snippet.order, snippetData});
			points += snippet.points;

			/* Special case: production cap */
			if (snippet.id === 'notEnoughtProdCap') {
				let hasBeenShownPreviously = false;
				gameRounds.forEach((round) => {
					if (
						round.report && 
						round.report.some((r) => {return r.snippetId === snippet.id;})
					) {
						hasBeenShownPreviously = true;
					}
				});
				if (!hasBeenShownPreviously) {
					selectedReportSnippets[selectedReportSnippets.length - 1].addText2 = true;
				}
			}
		}
	});

	/* Order snippets */
	selectedReportSnippets = sortArrayByProperty(selectedReportSnippets, 'order', 'ASC');

	return selectedReportSnippets;
};


/**
 * Check if report snippet conditions are met
 * @param {string} snippetId 
 * @param {array} conditions 
 * @param {array} gameRounds 
 * @param {array} bankruptcies 
 * @returns 
 */
function checkIfReportConditionsAreMet(snippetId, conditions, gameRounds, bankruptcies) {
	let conditionsAreMet = true;
	let snippetData = {};

	if (conditions && conditions.length > 0) {
		/* Get data for finished round */
		const roundData = gameRounds[gameRounds.length - 1];
		const bankruptcyData = bankruptcies.find((b) => {
			return b.roundNumber === roundData.roundNumber;
		});

		if (roundData) {
			/* Loop over conditions */
			conditions.forEach((condition) => {
				if (!conditionsAreMet) return;

				/* Default: condition is not met */
				conditionsAreMet = false;

				/* Bankruptcy */
				if (condition.type === 'bankruptcy' && bankruptcyData) {
					if (bankruptcies.length === 1) {
						if (condition.subtype === 'first-loan' && !bankruptcyData.isGameOver) {
							conditionsAreMet = true;
						}
						if (condition.subtype === 'first-no-loan' && bankruptcyData.isGameOver) {
							conditionsAreMet = true;
						}
					} else if (bankruptcies.length > 1 && condition.subtype === 'second') {
						conditionsAreMet = true;
					}
					if (conditionsAreMet) {
						snippetData.deficit = addThousandSeparator(bankruptcyData.deficit);
					}
				}

				/* Round number of finished round */
				if (condition.type === 'round-finished' && condition.roundNumber === roundData.roundNumber) {
					conditionsAreMet = true;

					if (snippetId === 'finalRound') {
						let totalProfit = 0;
						gameRounds.forEach((round) => {
							if (round && round.actualBudget && round.actualBudget.hasOwnProperty('profit')) {
								totalProfit += round.actualBudget['profit'];
							}
						});
						snippetData.totalProfit = totalProfit;
						snippetData.partners = 
							roundData.stats['number-of-owners'] + roundData.stats['number-of-partners'];
						snippetData.totalProfitPerPartner = addThousandSeparator(Math.floor(
							totalProfit / (roundData.stats['number-of-owners'] + roundData.stats['number-of-partners'])
						));
					}
				}

				/* Min number of finished round */
				if (
					condition.type === 'min-round' &&
					roundData.roundNumber >= condition.roundNumber
				) {
					conditionsAreMet = true;
				}

				/* Not enough t-shirts */
				if (
					condition.type === 'notEnoughTshirts' && 
					roundData.actualBudget['extra-tshirts-bought'] > 0
				) {
					conditionsAreMet = true;
					snippetData.extraTshirts = Math.round(roundData.actualBudget['extra-tshirts-bought']);
					snippetData.extraPrice = addThousandSeparator(
						roundData.actualBudget['extra-tshirts-bought'] * appConfig.extraPurchasePrice
					);
				}

				/* Not high enough production capacity */
				if (
					condition.type === 'notEnoughtProdCap' &&
					roundData.actualBudget['missed-tshirt-sales'] > 0
				) {
					conditionsAreMet = true;
					snippetData.missedSales = roundData.actualBudget['missed-tshirt-sales'];
					snippetData.missedEarnings = addThousandSeparator(Math.floor(
						roundData.actualBudget['missed-tshirt-sales'] * roundData.stats['sales-price']));
				}

				/* Positive / negative turnover change */
				if (condition.type === 'turnover-change') {
					let prevRoundTurnover = 0;
					if (gameRounds.length > 1) {
						prevRoundTurnover = gameRounds[gameRounds.length - 2].actualBudget['turnover'];
					} else {
						const initialBasicStats = getInitialStats();
						const initialStats = calculateCurrentStats({stats: initialBasicStats, selectedActions: []});
						const initialPredictedBudget = calculateBudget(initialStats);
						prevRoundTurnover = initialPredictedBudget['turnover'];
					}

					const turnoverChangePercent = 
						((roundData.actualBudget['turnover'] - prevRoundTurnover) / 
							Math.abs(prevRoundTurnover)) * 100;
					if (
						condition.subtype === 'positive' && 
						turnoverChangePercent > 0
					) {
						conditionsAreMet = true;
					}
					if (
						condition.subtype === 'negative' && 
						turnoverChangePercent < 0
					) {
						conditionsAreMet = true;
					}		
					
					if (conditionsAreMet) {
						
						snippetData.turnoverChange = (Math.floor(Math.abs(turnoverChangePercent)) < 1
							? '< 1'
							: Math.floor(Math.abs(turnoverChangePercent))
						);
						snippetData.turnover = addThousandSeparator(roundData.actualBudget['turnover']);
						snippetData.profit = addThousandSeparator(roundData.actualBudget['profit']);
						snippetData.liquidity = addThousandSeparator(roundData.actualBudget['liquidity']);
					}
				}

				/* Unexpected turnover */
				if (condition.type === 'unexpected-turnover') {
					const turnoverDiff = roundData.actualBudget['turnover'] - roundData.predictedBudget['turnover'];
					const turnoverDiffPercent = (turnoverDiff / roundData.actualBudget['turnover']) * 100;
					if (
						(condition.subtype === 'positive' && turnoverDiffPercent >= condition.limitPercent) ||
						(condition.subtype === 'negative' && turnoverDiffPercent <= condition.limitPercent)
					) {
						conditionsAreMet = true;
					}
				}

				/* Selected action option */
				if (
					condition.type === 'action-option' &&
					roundData.selectedActions.some((a) => {
						return (a.actionId === condition.actionId && a.actionOptionId === condition.actionOptionId);
					}) 
				) {
					conditionsAreMet = true;

					if (snippetId === 'onlineMarketing') {
						const newCustomers = roundData.stats['customers-prev-round'] - roundData.stats['customers'];
						if (newCustomers > 0) {
							snippetData.newCustomers = newCustomers;
							snippetData.newCustomersPercent = 
								Math.floor((newCustomers / roundData.stats['customers-prev-round']) * 100.);
						} else {
							conditionsAreMet = false;
						}
					}
				}

				/* Not repeatable */
				if (condition.type === 'not-repeatable') {
					let hasBeenShownPreviously = false;
					gameRounds.forEach((round) => {
						if (
							round.report && 
							round.report.some((r) => {return r.snippetId === snippetId;})
						) {
							hasBeenShownPreviously = true;
						}
					});
					if (!hasBeenShownPreviously) {
						conditionsAreMet = true;
					}
				}
			});
		} else {
			conditionsAreMet = false;
		}
	}


	return {conditionsAreMet, snippetData};


}