import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './budget-stat.scss';
import resetAnimation from 'helpers/animation-helper';

const BudgetStat = ({statId, statValue, statUnit}) => {
	const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);
	const [animated, setAnimated] = useState(false);
	const [oldValue, setOldValue] = useState(statValue);
	const [isAffected, setIsAffected] = useState(false);
	const [actionChosen, setActionChosen] = useState(false);
	
	window.addEventListener('actionChosen', (e) => {
		setActionChosen(true);
	}, false);

	useEffect(() => {
		if (actionChosen) {
			if (statValue !== oldValue) {
				setOldValue(statValue);
				setIsAffected(true);
			} else {
				setIsAffected(false);
			}
			setActionChosen(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionChosen]);

	useEffect(() => {
		if (isFirstTimeRender) {
			// Make sure animation does not run on first render
			setIsFirstTimeRender(false);
		} else {
			if (animated) {
				resetAnimation(statId);
			} else {
				// First time animation
				setAnimated(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statValue]);
	
	return (
		<div id={statId} className={'BudgetStat ' + 
			((statId === 'profit-2' || statId === 'liquidity-3') && statValue < 0 ? ' negative' : '') +
			(animated ? ' animated' : '') + 
			(isAffected ? ' affected' : '')}
		>
			<span>{statValue + (statUnit ? ' ' + statUnit : '')}</span>
		</div>
	);
};


BudgetStat.propTypes = {
	statId: PropTypes.string.isRequired,
	statValue: PropTypes.any.isRequired,
	statUnit: PropTypes.string,
};


export default BudgetStat;