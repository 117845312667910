import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {statsData} from 'data/stats-data';
import {addThousandSeparator} from 'helpers/math-helper';
import resetAnimation from 'helpers/animation-helper';
import './stat.scss';

const Stat = ({statId, statValue, gameData, currentStats, updateGameData}) => {
	const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);
	const [animated, setAnimated] = useState(false);
	const [oldValue, setOldValue] = useState(statValue);
	const [isAffected, setIsAffected] = useState(false);
	const [actionChosen, setActionChosen] = useState(false);
	
	window.addEventListener('actionChosen', (e) => {
		setActionChosen(true);
	}, false);

	useEffect(() => {
		if (actionChosen) {
			if (statValue !== oldValue) {
				setOldValue(statValue);
				setIsAffected(true);
			} else {
				setIsAffected(false);
			}
			setActionChosen(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionChosen]);

	useEffect(() => {
		if (isFirstTimeRender) {
			// Make sure animation does not run on first render
			setIsFirstTimeRender(false);
		} else {
			if (animated) {
				resetAnimation(statId);
			} else {
				// First time animation
				setAnimated(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statValue]);

	/* Get stat data */
	const statData = statsData.find((stat) => {return stat.id === statId;});
	if (!statData) return null;

	/* Update stat value (input) */
	const handleInputValue = (event) => {
		let newStats = JSON.parse(JSON.stringify(gameData.stats));
		newStats[statId] = event.target.value.length > 0 ? parseInt(event.target.value) : event.target.value;
		updateGameData({stats: newStats});
	};

	/* Stat type: range */
	if (statData.type === 'range') {
		const valuePercent = (statValue / (statData.maxVal - statData.minVal + 1)) * 100;
		return (
			<div className={'Stat ' + statData.type}>
				<div className="Stat-track">
					<div className="Stat-indicator" style={{width: valuePercent + '%'}} />
					<div className="Stat-value">
						<span>{statValue}/{statData.maxVal}</span>
					</div>
				</div>
			</div>
		);
	}

	/* Stat type: input */
	if (statData.type === 'input') {
		return (
			<div className={'Stat ' + statData.type}>
				<input 
					type="number"
					value={statValue}
					placeholder="0"
					onChange={(e) => {handleInputValue(e);}}
				/>
			</div>
		);
	}

	/* Stat type: default (number) */
	let formattedStatValue = statValue;
	if (statId !== 'buying-customers') {
		formattedStatValue = addThousandSeparator(Math.round(formattedStatValue));
	} else {
		formattedStatValue = formattedStatValue.toString().replace('.', ',');
	}
	
	return (
		<div id={statId} className={'Stat ' + 
			statData.type + 
			(animated ? ' animated' : '') + 
			(isAffected ? ' affected' : '')}
		>
			<span>{formattedStatValue + (statData.unit ? ' ' + statData.unit : '')}</span>
		</div>
	);
};


Stat.propTypes = {
	statId: PropTypes.string.isRequired,
	statValue: PropTypes.any.isRequired,
	currentStats: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	updateGameData: PropTypes.func.isRequired
};


export default Stat;