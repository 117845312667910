
import React from 'react';
import Logo from 'components/ui/logo/logo';
import {gameUiTexts} from 'data/ui-texts';
import './loading-page.scss';

const LoadingPage = () => {
	return (
		<div className='LoadingPage'>
			<div className="LoadingPage-logo"><Logo /></div>
			<div className="LoadingPage-subtitle">
				<span>{gameUiTexts.subtitle}</span>
			</div>
			<div className="LoadingPage-text"><span>{gameUiTexts.loadingGame}</span></div>
		</div>
	);
};


export default LoadingPage;