
import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {areasData} from 'data/areas-data';
import {roundsData} from 'data/rounds-data';
import {gameUiTexts} from 'data/ui-texts';
import {calculateCurrentStats, calculateBudget} from 'helpers/stat-and-budget-helpers';
import Background from 'components/ui/background/background';
import Logo from 'components/ui/logo/logo';
import RoundNumber from 'components/ui/round-number/round-number';
import AreaNavigation from 'components/ui/area-navigation/area-navigation';
import AreaStats from 'components/ui/area-stats/area-stats';
import Budget from 'components/ui/budget/budget';
import SelectedActionsCounter from 'components/ui/selected-actions-counter/selected-actions-counter';
import AreaActions from 'components/ui/area-actions/area-actions';
import AreaActionOptions from 'components/ui/area-action-options/area-action-options';
import AreaPagesBtn from 'components/ui/area-pages-btn/area-pages-btn';
import AreaPages from 'components/ui/area-pages/area-pages';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import DictionaryPopup from 'components/ui/dictionary-popup/dictionary-popup';
import './game.scss';


const Game = (props) => {
	const {
		animateEnterShop,
		areaId, 
		selectedActionId, 
		selectedAreaPageId, 
		gameData, 
		popupData,
		goToArea, 
		selectAction, 
		confirmSelectedActions,
		selectPageId, 
		togglePopup,
		updateGameData,
		resetGameData,
		dictionaryPopupData,
		toggleDictionary
	} = props;

	/* Get area data */
	const areaData = areasData.find((area) => {return area.id === areaId;});

	/* Get current stats and predicted budget */
	const currentStats = calculateCurrentStats(gameData);
	const predictedBudget = calculateBudget(currentStats);

	/* Check if game over (last round played or bankrupt) */
	let isGameOver = false;
	if (
		gameData.roundNumber > roundsData.length ||
		gameData.isFullyBankrupt === true
	) {
		isGameOver = true;
	}

	return (
		<div className={'Game ' + areaId + (animateEnterShop ? ' enterShop' : '')}>
			{/* Background */}
			<Background areaId={areaId} gameData={gameData} selectAction={selectAction} />

			<div className="Game-interface">
				{/* Reset btn (dev tool) */}
				{(appConfig.env === 'development' || appConfig.env === 'test') &&
				<div className="Game-resetBtn">
					<Button classes={['blue']} text={gameUiTexts.reset} onClick={() => {resetGameData();}} />
				</div>
				}

				{/* Dictionary btn */}
				<div 
					className={'Game-dictionaryButton' + (dictionaryPopupData ? ' popupIsOpen' : '')} 
					onClick={() => {toggleDictionary({id: ''});}}
				/>

				{/* Logo */}
				<div className='Game-logo'>
					<Logo type="game" />
				</div>

				<div className="Game-roundNumber">
					<RoundNumber gameData={gameData} />
				</div>

				{/* Area navigation */}
				<div className="Game-navigation">
					<AreaNavigation 
						isFullyBankrupt={gameData.isFullyBankrupt === true}
						areaId={areaId} 
						goToArea={goToArea}
					/>
				</div>

				{/* Area stats */}
				<div className="Game-areaStats">
					<AreaStats 
						areaId={areaId}
						currentStats={currentStats}
						gameData={gameData}
						updateGameData={updateGameData}
						toggleDictionary={toggleDictionary}
					/>
				</div>

				{/* Area pages toggle btn */}
				{(gameData.roundNumber > 1 && areaData.pages) && 
					<div className={'Game-areaPagesBtn ' + areaId}>
						<AreaPagesBtn 
							selectedAreaPageId={selectedAreaPageId}
							areaData={areaData}
							selectPageId={selectPageId}
						/>
					</div>
				}

				{/* Budget */}
				<div className="Game-budget">
					<Budget 
						type="predicted"
						areaId={areaId}
						title={gameUiTexts.budgetThisQuarter}
						gameBudget={predictedBudget}
						toggleDictionary={toggleDictionary}
					/>
				</div>

				{/* Confirm selected actions btn */}
				{!isGameOver && <div className="Game-confirmBtn">
					<Button 
						classes={['fullHeight', 'yellow']}
						text={gameUiTexts.go}
						onClick={() => {confirmSelectedActions();}}
					/>
				</div>}

				{/* Start new game btn */}
				{isGameOver && <div className="Game-newGameBtn">
					<Button 
						classes={['fullHeight', 'yellow']}
						text={gameUiTexts.startNewGame}
						onClick={() => {resetGameData();}}
					/>
				</div>}

				{/* Number of selected actions */}
				{!isGameOver && <div className="Game-selectedActionsCounter">
					<SelectedActionsCounter gameData={gameData} />
				</div>}

				{/* Selected action options */}
				{selectedActionId && <div className="Game-actionOptions">
					<AreaActionOptions 
						areaId={areaId}
						actionId={selectedActionId}
						currentStats={currentStats}
						gameData={gameData}
						updateGameData={updateGameData}
						togglePopup={togglePopup}
					/>
				</div>}

				{/* Area pages */}
				{selectedAreaPageId && <div className="Game-areaPages">
					<AreaPages 
						areaId={areaId}
						pageId={selectedAreaPageId}
						gameData={gameData}
						predictedBudget={predictedBudget}
						selectPageId={selectPageId}
						toggleDictionary={toggleDictionary}
					/>
				</div>}

				{/* Actions */}
				{!isGameOver && <div className="Game-areaActions">
					<AreaActions 
						areaId={areaId}
						gameData={gameData}
						selectedActionId={selectedActionId}
						selectAction={selectAction}
					/>
				</div>}
			</div>

			{/* Popup */}
			{popupData && <Popup popupData={popupData} togglePopup={togglePopup} />}
			{dictionaryPopupData && 
				<DictionaryPopup popupData={dictionaryPopupData} closePopup={() => {toggleDictionary();}}/>
			}
		</div>
	);
};

Game.propTypes = {
	animateEnterShop: PropTypes.bool.isRequired,
	areaId: PropTypes.string.isRequired,
	selectedActionId: PropTypes.string,
	selectedAreaPageId: PropTypes.string,
	gameData: PropTypes.object.isRequired,
	popupData: PropTypes.object,
	dictionaryPopupData: PropTypes.object,
	goToArea: PropTypes.func.isRequired,
	selectAction: PropTypes.func.isRequired,
	confirmSelectedActions: PropTypes.func.isRequired,
	selectPageId: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
	toggleDictionary: PropTypes.func.isRequired,
	updateGameData: PropTypes.func.isRequired,
	resetGameData: PropTypes.func.isRequired,
};

export default Game;