import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup.scss';

const Popup = ({popupData, togglePopup}) => {
	// Popup data
	const hasCloseButton = (popupData.hasCloseButton ? true : false);
	const type = (popupData.type ? popupData.type : null);
	const title = (popupData.title ? popupData.title : null);
	const text = (popupData.text ? popupData.text : null);
	const buttons = (popupData.buttons ? popupData.buttons : null);


	// Method containing on click actions for button click
	const handleButtonClick = (button) => {
		button.action(...button.parameters);
		if (button.closePopup === true) togglePopup(null);
	};

	return (
		<div className={'Popup' + (type ? ' ' + type : '')}>
			<div className="Popup-content">
				{hasCloseButton && <div className='Popup-closeButton' onClick={() => {togglePopup(null);}}/>}
				{title && <div className="Popup-title"><span>{title}</span></div>}
				{text && <div className="Popup-text">{renderMarkdown(text)}</div>}
				{buttons && <div className={'Popup-buttons' + (buttons.length === 1 ? ' single' : '')}>
					{buttons.map((button, index) => {
						let buttonClasses = ['popup'];
						if (button.classes) {
							button.classes.forEach((btnClass) => {buttonClasses.push(btnClass);});
						}
						return (
							<Button 
								key={index}
								isLoading={button.isLoading ? true : false}
								classes={buttonClasses} 
								text={button.text} 
								onClick={() => {handleButtonClick(button);}}
							/>
						);
					})}
				</div>}
			</div>	
		</div>
	);
};

Popup.propTypes = {
	popupData: PropTypes.object.isRequired,
	togglePopup: PropTypes.func.isRequired
};

export default Popup;
