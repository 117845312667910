import React from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import {statsData} from 'data/stats-data';
import Stat from 'components/ui/stat/stat';
import './area-stats.scss';

const AreaStats = ({areaId, currentStats, gameData, updateGameData, toggleDictionary}) => {
	/* Get area data */
	const areaData = areasData.find((a) => {return a.id === areaId;});

	/* Open dictionary */
	const openDictionary = (dictionaryId) => {
		if (dictionaryId && dictionaryId.length > 0) {
			toggleDictionary({id: dictionaryId});
		}
	};
	
	/* No stats to show */
	if (!areaData.stats || areaData.stats.length === 0) return null;

	return (
		<div className={'AreaStats'}>
			<div className={'AreaStats-content'}>
				<div className={'AreaStats-title'}>
					<span>{areaData.title}</span>
				</div>
				{areaData.stats.map((statsGroup, index) => {
					return (
						<div key={index} className="AreaStats-group">
							{statsGroup.map((statId) => {
								const statData = statsData.find((s) => {return s.id === statId;});
								if (!statData) return null;
								const statValue = currentStats[statId];
								return (
									<div 
										key={statId} 
										className={'AreaStats-stat ' + statData.type
											+ (statData.dictionaryId.length > 0 ? ' dictionaryEntry' : '')}
									>
										<div className="AreaStats-statLabel" 
											onClick={() => {openDictionary(statData.dictionaryId);}}>
											<span>{statData.title}</span>
										</div>
										<div className={'AreaStats-statValue ' + statData.type}>
											<Stat 
												statId={statId} 
												statValue={statValue}
												currentStats={currentStats}
												gameData={gameData}
												updateGameData={updateGameData} 
											/> 
										</div>
									</div>
								);	
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

AreaStats.propTypes = {
	areaId: PropTypes.string.isRequired,
	currentStats: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	updateGameData: PropTypes.func.isRequired,
	toggleDictionary: PropTypes.func.isRequired,
};


export default AreaStats;