import React from 'react';
import PropTypes from 'prop-types';
import {getDisplayedDesigns} from 'helpers/game-helper';
import './background.scss';

const Background = ({areaId, gameData, selectAction}) => {

	/**
	 * Background click
	 */
	const handleBackgroundClick = () => {
		window.dispatchEvent(new Event('actionChosen')); 
		selectAction(null);
	};

	/* Get displayed t-shirt designs */
	const displayedDesigns = getDisplayedDesigns(gameData);
	return (
		<div className={'Background ' + areaId} onClick={() => {handleBackgroundClick();}}>
			{/* Location backgrounds */}
			<div className="Background-location shop">
				{displayedDesigns.map((design, index) => {
					return (
						<div key={index} className={'Background-design shop-' + (index + 1) + ' ' + design} />
					);
				})}
			</div>
			<div className="Background-location production">
				{displayedDesigns.map((design, index) => {
					return (
						<div key={index} className={'Background-design production-' + (index + 1) + ' ' + design} />
					);
				})}
			</div>
			<div className="Background-location office">
				{displayedDesigns.length > 0 && 
					<div className={'Background-design office-1 ' + displayedDesigns[displayedDesigns.length - 1]} />}
			</div>

			{/* Character backgrounds */}
			<div className="Background-characters shop" />
			<div className="Background-characters production" />
			<div className="Background-characters office" />
		</div>
	);
};


Background.propTypes = {
	areaId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	selectAction: PropTypes.func.isRequired
};


export default Background;