import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {areasData} from 'data/areas-data';
import {gameUiTexts} from 'data/ui-texts';
import {
	checkIfActionOptionIsAvailable, 
	checkIfActionOptionCanBeSelected,
	checkIfActionHasBeenSelectedInPrevRounds,
	getNewPartnerBuyInPrice
} from 'helpers/game-helper';
import {addThousandSeparator} from 'helpers/math-helper';
import AreaActionOption from 'components/ui/area-action-option/area-action-option';
import AreaActionOptionBox from 'components/ui/area-action-option-box/area-action-option-box';
import AreaActionOptionSelect from '../area-action-option-select/area-action-option-select';
import './area-action-options.scss';

const AreaActionOptions = (props) => {
	/* Props */
	const {
		areaId, 
		actionId, 
		currentStats, 
		gameData, 
		updateGameData, 
		togglePopup
	} = props;

	/**
	 * Track which action option's dropdown is active (i.e. open)
	 */
	const [actionOptionIdOfActiveDropdown, setActionOptionIdOfActiveDropdown] = useState(null);

	/* Get selected action option */
	const selectedActionOptionData = (gameData.selectedActions.some((sa) => {return sa.actionId === actionId;}) 
		? gameData.selectedActions.find((sa) => {return sa.actionId === actionId;})
		: null
	);

	/**
	 * Select action option
	 * @param {string} optionId 
	 */
	const handleSelectActionOption = (optionId, value = null) => {		
		const selectedActions = JSON.parse(JSON.stringify(gameData.selectedActions));
		const selectedActionIndex = gameData.selectedActions.findIndex((sa) => {return sa.actionId === actionId;});

		if (
			selectedActionIndex >= 0 && 
			selectedActionOptionData &&
			selectedActionOptionData.actionOptionId === optionId && 
			(!value || value === selectedActionOptionData.value)
		) {
			/* De-select */
			selectedActions.splice(selectedActionIndex, 1);
		} else {
			const numberOfSelectedActions = gameData.selectedActions.length; 

			if (selectedActionIndex < 0 && numberOfSelectedActions >= appConfig.actionsToSelectPerRound) {
				/* Cannot select - max number of actions already selected */
				const popupData = {
					type: 'warning',
					text: gameUiTexts.maxOptionsSelected,
					buttons: [{
						action: togglePopup,
						parameters: [null],
						text: gameUiTexts.ok,
						classes: ['blue']
					}]
				};
				togglePopup(popupData);
			} else {
				if (selectedActionIndex >= 0) {
					/* Update action */
					selectedActions[selectedActionIndex].actionOptionId = optionId;
					selectedActions[selectedActionIndex].value = value;
				} else {
					selectedActions.push({actionId: actionId, actionOptionId: optionId, value: value});
				}
			}
		}
		window.dispatchEvent(new Event('actionChosen'));
		updateGameData({selectedActions});
	};

	/**
	 * Deselect option
	 */
	const handleDeselectActionOption = () => {
		const selectedActions = JSON.parse(JSON.stringify(gameData.selectedActions));
		const selectedActionIndex = selectedActions.findIndex((sa) => {return sa.actionId === actionId;});
		if (selectedActionIndex >= 0 ) {
			selectedActions.splice(selectedActionIndex, 1);
			window.dispatchEvent(new Event('actionChosen'));
			updateGameData({selectedActions});
		}
	};

	/**
	 * Action is not selectable -> show feedback (if available)
	 * @param {object} option 
	 */
	const handleActionOptionIsNotSelectable = (option) => {
		const {canBeSelected, feedback} = checkIfActionOptionCanBeSelected(option, gameData);

		if (!canBeSelected && feedback) {
			if (
				feedback.condition.type === 'minimum-budget-stat' && 
				gameUiTexts.optionCannotBeSelectedFeedback.minBudgetStat
			) {
				if (gameUiTexts.optionCannotBeSelectedFeedback.minBudgetStat[feedback.condition.statId]) {
					const text = JSON.parse(JSON.stringify(
						gameUiTexts.optionCannotBeSelectedFeedback.minBudgetStat[feedback.condition.statId]
					)).replace('%value%', feedback.value).replace('%required%', feedback.required);

					const popupData = {
						type: 'warning2',
						text: text,
						buttons: [{
							action: togglePopup,
							parameters: [null],
							text: gameUiTexts.ok,
							classes: ['blue']
						}]
					};
					togglePopup(popupData);
				}
			}
		}
	};


	/* Get area data */
	const areaData = areasData.find((area) => {return area.id === areaId;});

	/* Get action data */
	const actionData = areaData.actions.find((action) => {return action.id === actionId;});



	return (
		<div className={'AreaActionOptions'}>
			<div className={'AreaActionOptions-options ' + (actionData.type ? actionData.type : '')}>
				{actionData.options.map((option) => {
					/* Action is locked or blocked (i.e. not shown) */
					if (!checkIfActionOptionIsAvailable(actionData.id, option, gameData)) return null;
					
					/* Check if action can be selected */
					const {canBeSelected} = checkIfActionOptionCanBeSelected(option, gameData);

					/* Check if selected / confirmed */
					const isSelected = selectedActionOptionData 
						? option.id === selectedActionOptionData.actionOptionId
						: false;
					const isConfirmed = isSelected;

					/* Get price & modified description */
					let price = null;
					let description = JSON.parse(JSON.stringify(option.description));
					if (option && option.effects) {
						/* Expense price */
						const expenseEffect = option.effects.find((e) => {return e.type === 'expense';});
						if (expenseEffect) {
							price = expenseEffect.price;
							if (price) {
								price = addThousandSeparator(price);
								description = description.replace('%price%', price);
							}
						}
					
						/* New partner buy-in price*/
						const sellPartEffect = option.effects.find((e) => {return e.type === 'new-partner';});
						if (sellPartEffect) {
							let buyInPrice = getNewPartnerBuyInPrice(gameData);
							if (buyInPrice) {
								buyInPrice = addThousandSeparator(buyInPrice);
								description = description.replace('%buyInPrice%', buyInPrice);
							}
						}
					}

					/* Option has been selected before - 2 special cases */
					if (option.id === 'marketing-a' || option.id === 'marketing-b') {
						let replacement = '';
						if (checkIfActionHasBeenSelectedInPrevRounds(actionId, option.id, gameData)) {
							replacement = option.replacement;
						}
						description = description.replace('%another%', replacement);
					}

					/* Get component */
					let OptionComponent = AreaActionOption;
					if (actionData.type === 'box') {
						OptionComponent = AreaActionOptionBox;
					}
					if (option.type === 'select' || option.type === 'select-optional') {
						OptionComponent = AreaActionOptionSelect;
					}

					return (
						<div key={option.id} className='AreaActionOptions-option'>
							<OptionComponent 
								canBeSelected={canBeSelected}
								isSelected={isSelected} 
								isConfirmed={isConfirmed}
								actionId={actionId}
								actionOptionIdOfActiveDropdown={actionOptionIdOfActiveDropdown}
								option={option} 
								price={price}
								description={description} 
								currentStats={currentStats}
								gameData={gameData}
								setActionOptionIdOfActiveDropdown={setActionOptionIdOfActiveDropdown}
								handleSelectActionOption={handleSelectActionOption} 
								handleDeselectActionOption={handleDeselectActionOption}
								handleActionOptionIsNotSelectable={handleActionOptionIsNotSelectable}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};


AreaActionOptions.propTypes = {
	areaId: PropTypes.string.isRequired,
	actionId: PropTypes.string.isRequired,
	currentStats: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	updateGameData: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
};


export default AreaActionOptions;