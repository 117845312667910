import React from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import AreaPageLastQuarter from './pages/area-page-last-quarter';
import AreaPageHistory from './pages/area-page-history';
import AreaPageReport from './pages/area-page-report';
import Button from 'components/ui/button/button';
import './area-pages.scss';

const AreaPages = ({areaId, pageId, gameData, predictedBudget, selectPageId, toggleDictionary}) => {
	/* Get area data */
	const areaData = areasData.find((area) => {return area.id === areaId;});

	/* No area data / pages */
	if (!areaData || !areaData.pages || areaData.pages.length === 0) return;

	/* Get page data */
	const pageIndex = areaData.pages.findIndex((page) => {return page.id === pageId;}); 
	const pageData = areaData.pages.find((page) => {return page.id === pageId;});

	let PageComponent = AreaPageLastQuarter;
	if (pageId === 'history') PageComponent = AreaPageHistory;
	if (pageId === 'report') PageComponent = AreaPageReport;

	return (
		<div className="AreaPages">
			<div className="AreaPages-nav">
				{areaData.pages.map((page) => {
					return (
						<div key={page.id} className="AreaPages-navItem">
							<Button 
								classes={['grey', 'pageNav']}
								onClick={() => {selectPageId(page.id);}}
								text={page.title}
							/>
						</div>
					);
				})}
			</div>
			<div className={'AreaPages-page index-' + pageIndex}>
				<div className="AreaPages-pageTitle"><span>{pageData.title}</span></div>
				<PageComponent
					pageData={pageData}
					gameData={gameData}
					predictedBudget={predictedBudget}
					toggleDictionary={toggleDictionary}
				/>
				<div className="AreaPages-closeBtn" onClick={() => {selectPageId(null);}}/>
			</div>
		</div>
	);
};


AreaPages.propTypes = {
	areaId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	predictedBudget: PropTypes.object.isRequired,
	selectPageId: PropTypes.func.isRequired,
	toggleDictionary: PropTypes.func.isRequired
};


export default AreaPages;