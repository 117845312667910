import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {budgetData} from 'data/budget-data';
import {addThousandSeparator} from 'helpers/math-helper';
import BudgetStat from '../budget-stat/budget-stat';
import './budget.scss';

const Budget = ({type, areaId, title, gameBudget, toggleDictionary}) => {
	/* Expanded budget */
	const [showExpandedBudget, setShowExpandedBudget] = useState(false);

	/* Auto-collapse budget when switching area */
	useEffect(() => {
		setShowExpandedBudget(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [areaId]);

	/* Open dictionary */
	const openDictionary = (dictionaryId) => {
		if (dictionaryId && dictionaryId.length > 0) {
			toggleDictionary({id: dictionaryId});
		}
	};


	return (
		<div className={'Budget' + (type ? ' ' + type : '') + (showExpandedBudget ? ' expanded' : '')}>
			<div className={'Budget-content'}>
				<div className={'Budget-title'}><span>{title}</span></div>
				{type === 'predicted' && 
					<div className="Budget-expandBtn" onClick={() => {setShowExpandedBudget(!showExpandedBudget);}}/>
				}
				{budgetData.map((budgetGroup, index) => {
					return (
						<div key={index} className="Budget-group">
							{budgetGroup.map((budgetData) => {
								let value = '?';
								if (gameBudget.hasOwnProperty(budgetData.id)) {
									value = addThousandSeparator(Math.round(gameBudget[budgetData.id]));
								}
									
								return (
									<div 
										key={budgetData.id + '-' + index} 
										className={'Budget-stat ' + budgetData.id 
											+ (budgetData.dictionaryId.length > 0 ? ' dictionaryEntry' : '')
											+ (budgetData.isExpandedBudget && !showExpandedBudget ? ' hidden' : '')
											+ (budgetData.isExpandedBudget ? ' expanded' : '')}
									>
										<div className="Budget-statLabel" 
											onClick={() => {openDictionary(budgetData.dictionaryId);}}>
											<span>{budgetData.title}</span>
										</div>
										<BudgetStat
											statId={budgetData.id + '-' + index}
											statValue={value}
											statUnit={budgetData.unit}
										/>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};


Budget.propTypes = {
	type: PropTypes.string,
	areaId: PropTypes.string,
	title: PropTypes.string.isRequired,
	gameBudget: PropTypes.object.isRequired,
	toggleDictionary: PropTypes.func.isRequired,
};


export default Budget;