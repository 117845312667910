import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import YouTube from 'react-youtube';
import './popup-video.scss';

const PopupVideo = ({setShowVideoPopup}) => {
	/* Video state and ref */
	const [videoState, setVideoState] = useState('loading');
	const [videoRef, setVideoRef] = useState(null);

	/* Play video */
	const handlePlay = (e) => {
		e.stopPropagation();
		if (videoState === 'loading') return;
		if (videoRef && videoRef.playVideo)  videoRef.playVideo();
	};

	/* Video ended */
	const videoEnded = () => {
		setVideoState('ended');

		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	};	
 
	return (
		<div className="PopupVideo" onClick={() => {setShowVideoPopup(false);}}>
			<div className="PopupVideo-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="PopupVideo-closeBtn" onClick={() => {setShowVideoPopup(false);}}/>
				<div className="PopupVideo-videoWrap">
					<YouTube 
						className={'PopupVideo-video ' + videoState} 
						videoId={appConfig.videoId} 
						onReady={(e) => {setVideoRef(e.target); setVideoState('ready');}}
						onPlay={() => {setVideoState('playing');}}
						onEnd={() => {videoEnded();}}
						opts={{
							height: '100%', 
							width: '100%', 
							playerVars: {
								controls: 1,
								origin: appConfig.gameUrl
							}
						}}
					/>
					<div 
						className={'PopupVideo-videoPlayBtn ' + videoState} 
						onClick={(e) => {handlePlay(e);}} 
					/>
				</div>
			</div>
		</div>
	);
};

PopupVideo.propTypes = {
	setShowVideoPopup: PropTypes.func.isRequired,
};

export default PopupVideo;
