const areasData = [
	{
		id: 'shop',
		title: 'Butik',
		stats: [
			['customers', 'buying-customers', 'sales-price', 'experience']
		],
		actions: [
			{
				id: 'marketing',
				title: 'Markedsføring',
				options: [
					{
						id: 'marketing-a',
						title: 'Gadeevent',
						description: 'I holder %another%en event uden for butikken. En af jeres venner kommer og er DJ, og der er gratis drikkevarer til de 50 første.',
						replacement: 'endnu ',
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Gadeevent',
								price: 20000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 2
							},
						]
					},
					{
						id: 'marketing-b',
						title: 'Giveaways på lokale gymnasier',
						description: 'I tager ud til et %another%gymnasium og giver shirts ud til dem der tør gøre noget skørt, som I kan smide på sociale medier, iført en af jeres shirt.',
						replacement: 'nyt ',
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Giveaways på lokale gymnasier',
								price: 15000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 1.5
							},
						]
					},
					{
						id: 'marketing-c',
						title: 'Bod til metal-festival',
						description: 'En lokal festival trækker alle de unge i området til. I køber en bod på festivallen og kan sælge shirts der.',
						isLocked: true,
						isRepeatableAfterXRounds: 2,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Bod til metal-festival',
								price: 35000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 3
							},
						]
					},
					{
						id: 'marketing-d',
						title: 'Online markedsføring',
						description: `I igangsætter kampagner på sociale medier.`,
						isRepeatableAfterXRounds: 0,
						isLocked: true,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Online markedsføring',
								price: 50000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 4
							},
						]
					},
					{
						id: 'marketing-e',
						title: 'Influencer',
						description: `I indgår en aftale med influenceren Swanja Morgengry om, at hun skal bruge jeres shirts og lave en række opslag om jeres butik.`,
						isRepeatableAfterXRounds: 2,
						isLocked: true,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Influencer',
								price: 90000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 5
							},
						]
					},
					{
						id: 'marketing-f',
						title: 'Plakatkampagne',
						description: `I får trykt en masse plakater, som I hænger op rundt omkring i byen!`,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'marketing-expenses',
								expenseTitle: 'Plakatkampagne',
								price: 5000,
							},
							{
								type: 'add-to-stat',
								statId: 'marketing-effect',
								statValue: 0.7
							},
						]
					}
				],
			},
			{
				id: 'offer-campaign',
				title: 'Prissætning',
				options: [
					{
						id: 'offer-campaign-a',
						title: 'Salgspris',
						description: 'Ændrer salgsprisen.',
						type: 'select',
						stepSize: 50,
						minVal: 50,
						maxVal: 1000,
						unit: 'kr',
						isRepeatableAfterXRounds: 0,
						statId: 'sales-price-selected',
						modifiesStatId: 'sales-price-base',
						effects: [
							{
								type: 'change-stat-selected',
								statId: 'sales-price-selected',
								modifiesStatId: 'sales-price-base'
							}
						]
					},
					{
						id: 'offer-campaign-b',
						title: 'Tilbudskampagne 20%',
						description: 'En måned med 20% tilbud på alle shirts.',
						isRepeatableAfterXRounds: 3,
						effects: [
							{
								type: 'sale',
								value: 0.2
							},
							{
								type: 'add-to-stat',
								statId: 'buying-customers-boost',
								statValue: 5
							}
						]
					},
					{
						id: 'offer-campaign-c',
						title: 'Tilbudskampagne 40%',
						description: 'En måned med 40% tilbud på alle shirts.',
						isRepeatableAfterXRounds: 3,
						effects: [
							{
								type: 'sale',
								value: 0.4
							},
							{
								type: 'add-to-stat',
								statId: 'buying-customers-boost',
								statValue: 10
							}
						]
					},
				],
			},
			{
				id: 'shop',
				title: 'Butik',
				options: [
					{
						id: 'shop-a',
						title: 'Butiksindretning',
						description: 'I skifter indretningen til en cool New Yorker stil.',
						isRepeatableAfterXRounds: 0,
						isLocked: true,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Butiksindretning',
								price: 55500,
							},
							{
								type: 'add-to-stat',
								statId: 'experience',
								statValue: 3
							},
							{
								type: 'lock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-a'
							},
							{
								type: 'unlock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-d'
							}
						]
					},
					{
						id: 'shop-d',
						title: 'Butiksindretning',
						description: 'I skifter indretningen til en flippet low key stil.',
						isLocked: true,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Butiksindretning',
								price: 13500,
							},
							{
								type: 'add-to-stat',
								statId: 'experience',
								statValue: 1
							},
							{
								type: 'lock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-d'
							},
							{
								type: 'unlock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-a'
							}
						]
					},
					{
						id: 'shop-b',
						title: 'Butiksmedhjælper',
						description: 'I hyrer en af jeres søskende til at hjælpe med salget om lørdagen og når der er koncerter på spillestedet.',
						isLocked: true,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'additional-salaries',
								expenseTitle: 'Butiksmedarbejer',
								price: 3*8000,
								isPermanent: true
							},
							{
								type: 'add-to-stat',
								statId: 'experience',
								statValue: 2
							},
							{
								type: 'lock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-b'
							},
							{
								type: 'unlock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-c'
							}
						]
					},
					{
						id: 'shop-c',
						title: 'Opsig jeres butiksmedhjælper',
						description: '',
						isLocked: true,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'additional-salaries',
								expenseTitle: 'Butiksmedarbejer',
								price: -3*8000,
								isPermanent: true
							},
							{
								type: 'add-to-stat',
								statId: 'experience',
								statValue: -2
							},
							{
								type: 'unlock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-b'
							},
							{
								type: 'lock-action-option',
								actionId: 'shop',
								actionOptionId: 'shop-c'
							}
						]
					},
					
				],
			},
		]
	},
	{
		id: 'production',
		title: 'Værksted',
		stats: [
			['stock', 'purchase', 'purchase-price'],
			['prod-capacity', 'production-price'],
			['design-expenses', 'design-quality'], 
		],
		actions: [
			{
				id: 'tshirt-supplier',
				title: 'Indkøb',
				options: require('./supplier-actions-options').default
			},
			{
				id: 'buy-hardware',
				title: 'Hardware',
				options: [
					{
						id: 'buy-hardware-a',
						title: 'Ekstra printer',
						description: `Køb en ekstra printer og varmetryk-maskine.
							<br />Maskinerne koster %price% kr.`,
						isRepeatableAfterXRounds: 0,
						maxTimesSelectable: 2,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Indkøb af hardware',
								price: 10000,
							},
							{
								type: 'add-to-stat',
								statId: 'prod-capacity-base',
								statValue: 700
							},
							{
								type: 'unlock-action-type',
								actionId: 'hire-helper-production'
							}
						]
					},
					{
						id: 'buy-hardware-b',
						title: 'Direkte print',
						description: 'T-shirt printer til direkte tryk på t-shirts. Giver bedre kvalitet, øget og billigere produktion.',
						isLocked: true,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Indkøb af hardware',
								price: 150000,
							},
							{
								type: 'add-to-stat',
								statId: 'quality-score-modifier',
								statValue: 1
							},
							{
								type: 'add-to-stat',
								statId: 'prod-capacity-base',
								statValue: 1500
							},
							{
								type: 'add-to-stat',
								statId: 'purchase-price',
								statValue: -3
							},
							{
								type: 'unlock-action-type',
								actionId: 'hire-helper-production'
							}
						]
					},
				],
			},
			{
				id: 'new-designs',
				type: 'box',
				title: 'Designs',
				options: require('./design-actions-options').default
			},
			{
				id: 'hire-helper-production',
				title: 'Hjælper',
				isLocked: true,
				options: [
					{
						id: 'hire-helper-production-a',
						title: 'Hyr hjælper til produktion',
						description: 'Hyr en ven til at hjælpe til. Så kan I endnu bedre udnytte jeres nye printer.',
						isLocked: true,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'additional-salaries',
								expenseTitle: 'Hjælper til produktion',
								price: 3 * 8500,
								isPermanent: true
							},
							{
								type: 'multiply-stat',
								statId: 'prod-capacity-modifier',
								statValue: 1.5
							},
							{
								type: 'lock-action-option',
								actionId: 'hire-helper-production',
								actionOptionId: 'hire-helper-production-a'
							},
							{
								type: 'unlock-action-option',
								actionId: 'hire-helper-production',
								actionOptionId: 'hire-helper-production-b'
							}
						]
					},
					{
						id: 'hire-helper-production-b',
						title: 'Opsig jeres hjælper',
						description: '',
						isLocked: true,
						isRepeatableAfterXRounds: 0,
						effects: [
							{
								type: 'expense',
								expenseType: 'additional-salaries',
								expenseTitle: 'Hjælper til produktion',
								price: -3 * 8500,
								isPermanent: true
							},
							{
								type: 'multiply-stat',
								statId: 'prod-capacity-modifier',
								statValue: (1./1.5)
							},
							{
								type: 'unlock-action-option',
								actionId: 'hire-helper-production',
								actionOptionId: 'hire-helper-production-a'
							},
							{
								type: 'lock-action-option',
								actionId: 'hire-helper-production',
								actionOptionId: 'hire-helper-production-b'
							}
						]
					}
				],
			}
		]
	},
	{
		id: 'office',
		title: 'Kontor' ,
		pagesBtnText: 'Regnskab',
		pages: [
			{
				id: 'last-quarter',
				title: 'Sidste kvartal'
			},
			{
				id: 'history',
				title: 'Historik'
			}, 
			{
				id: 'report',
				title: 'Rapport'
			}    
		],
		actions: [
			{
				id: 'financing',
				title: 'Finansiering',
				options: [
					{
						id: 'financing-a',
						title: 'Dårligt og dyrt lille lån',
						description: `Kræver en omsætning på 250.000 for at kunne få lånet.
							<br />Lån 50.000 kr til 15%.`,
						conditions: [
							{
								type: 'minimum-budget-stat',
								statId: 'turnover',
								minVal: 250000
							}
						],
						effects: [
							{
								type: 'loan',
								amount: 50000,
								value: 1.15 * 50000,
								interest: 0.15,
								monthlyPayment: 8000,
							}
						]
					},
					{
						id: 'financing-b',
						title: 'Bedre lån',
						description: `Kræver en omsætning på 450.000 kr for at kunne få lånet.
							<br />Lån 150.000 kr til 15%`,
						conditions: [
							{
								type: 'minimum-budget-stat',
								statId: 'turnover',
								minVal: 450000
							}
						],
						effects: [
							{
								type: 'loan',
								amount: 150000,
								value: 1.15 * 150000,
								interest: 0.15,
								monthlyPayment: 7500,
							}
						]
					},
					{
						id: 'financing-c',
						title: 'Stort lån',
						description: `Banken tror på jer, og I kan tage et stort lån. Kræver en omsætning på 1.200.000 kr for at kunne få lånet.
							<br />Lån 350.000 kr til 8%`,
						conditions: [
							{
								type: 'minimum-budget-stat',
								statId: 'turnover',
								minVal: 600000
							}
						],
						effects: [
							{
								type: 'loan',
								amount: 350000,
								value: 1.08 * 150000,
								interest: 0.08,
								monthlyPayment: 8000,
							}
						]
					},
					{
						id: 'financing-d',
						title: 'Partner - sælg en del af jeres selskab',
						description: `Det betyder at I i stedet for at dele overskudet med 3 som I gør nu, skal dele det med 4. I har fået et tilbud fra en der tilbyder at køb en anpart for %buyInPrice% kr som I så vil få tilført som likvide midler`,
						effects: [
							{
								type: 'new-partner',
							}
						]
					},
				],
			},
			{
				id: 'innovation',
				title: 'Innovation',
				options: [
					{
						id: 'innovation-a',
						title: 'Lokale bands',
						description: `I mødes med folk fra spillestedet og snakker<br />om muligheder for samarbejde. Måske er der også bands hvor I må trykke deres logoer?`,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Lokale bands',
								price: 3000
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'indie_01',
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'indie_02',
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'indie_03',
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'indie_04',
							}
						]
					},
					{
						id: 'innovation-b',
						title: 'Research tur til Japan',
						description: 'I har fået en god forbindelse i kunstmiljøet i Japan, som har inviteret jer på research tur.',
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Research tur til Japan',
								price: 65000
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'kawaii_01'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'kawaii_02'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'kawaii_03'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'kawaii_04'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'kawaii_05'
							}
						]
					},
					{
						id: 'innovation-c',
						title: 'Artforums',
						description: `Engager jer på artforums på nettet for at finde ud af hvad der er det fedeste lige nu, og se om I kan få aftaler med nogle upcoming kunststjerner.`,
						effects: [
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'psychedelic_01'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'psychedelic_02'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'psychedelic_03'
							}
						]
					},
					{
						id: 'innovation-d',
						title: 'Metalfestival',
						description: `Det lokale spillested har en årlig metalfestival. I mødes med arrangørerne af den for at høre om der kunne være nogle muligheder for samarbejde.`,
						isLocked: true,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Metalfestival',
								price: 10000
							},
							{
								type: 'unlock-action-option',
								actionId: 'marketing',
								actionOptionId: 'marketing-c'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'metalband_01'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'metalband_02'
							},
							{
								type: 'unlock-action-option',
								actionId: 'new-designs',
								actionOptionId: 'metalband_03'
							}
						]
					},
					{
						id: 'innovation-e',
						title: 'Online markedsføring',
						description: `Væksthuset udbyder et kursus i online markedsføring.`,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Online markedsføring',
								price: 2600
							},
							{
								type: 'unlock-action-option',
								actionId: 'marketing',
								actionOptionId: 'marketing-d'
							},
							{
								type: 'unlock-action-option',
								actionId: 'marketing',
								actionOptionId: 'marketing-e'
							},
						]
					},
					{
						id: 'innovation-f',
						title: 'Nye produktionsteknikker',
						description: `Undersøg nye teknikker til produktion.`,
						effects: [
							{
								type: 'expense',
								expenseType: 'variable-expenses',
								expenseTitle: 'Online markedsføring',
								price: 7000
							},
							{
								type: 'unlock-action-option',
								actionId: 'buy-hardware',
								actionOptionId: 'buy-hardware-b'
							},
						]
					}

				],
			}
		]
	}
];

module.exports = {
	areasData
};