import React from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import Button from 'components/ui/button/button';
import './area-navigation.scss';

const AreaNavigation = ({isFullyBankrupt, areaId, goToArea}) => {
	/**
	 * Goes to area id, and dispatches event to stop action chosen animation
	 */
	const handleNavigation = (areaId) => {
		window.dispatchEvent(new Event('actionChosen'));
		goToArea(areaId);
	};
	
	return (
		<div className={'AreaNavigation' + (isFullyBankrupt ? ' disabled' : '')}>
			{areasData.map((area) => {
				const btnClasses = ['navigation'];
				if (area.id === areaId) {
					btnClasses.push('blue');
				} else {
					btnClasses.push('grey');
				}
				return (
					<div key={area.id} className="AreaNavigation-btn">
						<Button 
							isDisabled={isFullyBankrupt}
							text={area.title}
							classes={btnClasses}
							onClick={() => {handleNavigation(area.id);}}
						/>
					</div>
				);
			})}
		</div>
	);
};


AreaNavigation.propTypes = {
	isFullyBankrupt: PropTypes.bool.isRequired,
	areaId: PropTypes.string.isRequired,
	goToArea: PropTypes.func.isRequired
};


export default AreaNavigation;