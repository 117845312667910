import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './select.scss';

const Select = (props) => {
	const {
		isDisabled = false,
		type = null,
		placeholderText = null, 
		unit = null, 
		actionOptionId,
		actionOptionIdOfActiveDropdown,
		defaultIndex = 0, 
		selectedIndex = null, 
		options, 
		onSelect,
		setActionOptionIdOfActiveDropdown
	} = props;


	/* Dropdown status */
	const [dropdownIsOpen, setDropDown] = useState(false);

	/**
	 * Auto open/close dropdown
	 */
	useEffect(() => {
		if (actionOptionId) {
			setDropDown(actionOptionId === actionOptionIdOfActiveDropdown);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionOptionIdOfActiveDropdown]);

	/**
	 * Toggle dropdown
	 */
	const toggleDropdown = () => {
		if (isDisabled) return;

		const newDropdownIsOpen = !dropdownIsOpen;
		if (newDropdownIsOpen && actionOptionId) {
			setActionOptionIdOfActiveDropdown(actionOptionId);
		} else {
			setActionOptionIdOfActiveDropdown(null);
		}
		setDropDown(newDropdownIsOpen);
	};


	/* Select option from list / submit new option */
	const handleSelect = (optionIndex) =>  {
		if (isDisabled) return;
		onSelect(optionIndex);
		toggleDropdown();
	};

	/* Selected / default option */
	const selectedOption = selectedIndex >= 0 ? options[selectedIndex] : null;
	const defaultOption = options[defaultIndex];

	/* Filtered options */
	return (
		<div className={'Select ' + type
			+ (dropdownIsOpen ? ' open' : '') 
			+ (isDisabled ? ' disabled' : '')
		}>
			<div className="Select-selected" onClick={() => {toggleDropdown();}}>
				<span>{(selectedOption !== null
					? selectedOption 
					: defaultOption 
						? defaultOption 
						: placeholderText 
							? placeholderText 
							: ''
				) + (unit ? ' ' + unit : '')}</span>
			</div>
			<div className="Select-dropdown">
				{options.map((option, index) => {
					return (
						<div 
							key={option} 
							className={'Select-option' + (index === selectedIndex ? ' selected' : '')}
							onClick={() => {handleSelect(option);}}
						>
							<span>{option + (unit ? ' ' + unit : '')}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Select.propTypes = {
	isDisabled:PropTypes.bool, 
	actionOptionId: PropTypes.string,
	actionOptionIdOfActiveDropdown: PropTypes.string,
	type: PropTypes.string,
	placeholderText: PropTypes.string,
	unit: PropTypes.string,
	defaultIndex: PropTypes.number,
	selectedIndex: PropTypes.number,
	options: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	setActionOptionIdOfActiveDropdown: PropTypes.func,
};

export default Select;