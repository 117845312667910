const sharedProps = {
	type: 'select-optional',
	unit: 'stk',
	isRepeatableAfterXRounds: 0,
	statId: 'purchase-selected',
	modifiesStatId: 'purchase-base',
	statId2: 'supplier-selected',
	modifiesStatId2: 'supplier-base'
};

const supplierActionOptions = [
	{
		id: 'tshirt-supplier-a',
		title: 'Skift t-shirt-leverandør',
		title2: 'Skift antal bestilte t-shirts',
		description: 'T-shirts af medium kvalitet.',
		...sharedProps,
		minVal: 200,
		maxVal: 1000,
		stepSize: 50,
		effects: [
			{
				type: 'change-stat',
				statId: 'supplier-selected',
				statValue: 'tshirt-supplier-a'
			},
			{
				type: 'change-stat',
				statId: 'quality-score-base',
				statValue: 2
			},
			{
				type: 'change-stat',
				statId: 'purchase-price',
				statValue: 25
			},
			{
				type: 'change-stat-selected',
				statId: 'purchase-selected',
				modifiesStatId: 'purchase-base'
			}
		]
	},
	{
		id: 'tshirt-supplier-b',
		title: 'Skift t-shirt-leverandør',
		title2: 'Skift antal bestilte t-shirts',
		description: 'De bedste t-shirts, I kender til.',
		...sharedProps,
		minVal: 200,
		maxVal: 1500,
		stepSize: 100,
		effects: [
			{
				type: 'change-stat',
				statId: 'supplier-selected',
				statValue: 'tshirt-supplier-b'
			},
			{
				type: 'change-stat',
				statId: 'quality-score-base',
				statValue: 4
			},
			{
				type: 'change-stat',
				statId: 'purchase-price',
				statValue: 50
			},
			{
				type: 'change-stat-selected',
				statId: 'purchase-selected',
				modifiesStatId: 'purchase-base'
			}
		]
	},
	{
		id: 'tshirt-supplier-c',
		title: 'Skift t-shirt-leverandør',
		title2: 'Skift antal bestilte t-shirts',
		description: 'De bedste t-shirts i kender til, til en bedre pris, men større minimumsordre.',
		...sharedProps,
		stepSize: 350,
		minVal: 1500,
		maxVal: 5000,
		effects: [
			{
				type: 'change-stat',
				statId: 'supplier-selected',
				statValue: 'tshirt-supplier-c'
			},
			{
				type: 'change-stat',
				statId: 'quality-score-base',
				statValue: 4
			},
			{
				type: 'change-stat',
				statId: 'purchase-price',
				statValue: 35
			},
			{
				type: 'change-stat-selected',
				statId: 'purchase-selected',
				modifiesStatId: 'purchase-base'
			}
		]
	},
	{
		id: 'tshirt-supplier-d',
		title: 'Skift t-shirt-leverandør',
		title2: 'Skift antal bestilte t-shirts',
		description: 'De billigste.',
		...sharedProps,
		minVal: 150,
		maxVal: 1000,
		stepSize: 50,
		effects: [
			{
				type: 'change-stat',
				statId: 'supplier-selected',
				statValue: 'tshirt-supplier-d'
			},
			{
				type: 'change-stat',
				statId: 'quality-score-base',
				statValue: 1
			},
			{
				type: 'change-stat',
				statId: 'purchase-price',
				statValue: 15
			},
			{
				type: 'change-stat-selected',
				statId: 'purchase-selected',
				modifiesStatId: 'purchase-base'
			}
		]
	},
];

export default supplierActionOptions;