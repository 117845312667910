import {getInitialStats} from './stat-and-budget-helpers';
import {gameData} from 'data/game-data';
import {addThousandSeparator} from 'helpers/math-helper';

/**
 * Get default game data
 * @returns 
 */
function getDefaultGameData() {
	const defaultGameData = {
		roundNumber: 1,
		stats: getInitialStats(),
		loans: [Object.assign({}, gameData.initialLoan)],
		selectedActions: [], // selected actions in current round, resets when moving to new round
		rounds: [], // track selected action, stats, predicted budget and actual budget for each round
		unlockedActions: [
			{actionId: 'hire-helper-production', actionOptionId: 'hire-helper-production-a'},
			{actionId: 'shop', actionOptionId: 'shop-a'},
			{actionId: 'shop', actionOptionId: 'shop-b'},
			{actionId: 'shop', actionOptionId: 'shop-d'},
		], // initially locked action types / action options, can be (un)locked by other actions
		blockedActions: [], // already selected action options can (usually) not be selected again
	};

	return defaultGameData;
}

/**
 * Get displayed t-shirt designs
 * @param {object} gameData 
 */
function getDisplayedDesigns(gameData) {
	let displayedDesigns = [];

	/* Get 3 newest designs */
	let boughtDesigns = [];
	if (gameData.rounds && gameData.rounds.length > 0) {
		gameData.rounds.forEach((round) => {
			const newDesignsIndex = round.selectedActions.findIndex((sa) => {return sa.actionId === 'new-designs';});
			if (newDesignsIndex >= 0) {
				boughtDesigns.push(round.selectedActions[newDesignsIndex]);
			}
		});
	}
	if (boughtDesigns.length > 0) {
		/* Get newest t-shirt designs */
		if (boughtDesigns.length === 1) {
			/* 1 design bought */
			for (let i = 0; i < 3; i++) {
				displayedDesigns.push(boughtDesigns[0].actionOptionId);
			}	
		} else if (boughtDesigns.length === 2) {
			/* 2 designs bought */
			for (let i = 0; i < 3; i++) {
				if (i === 1) {
					displayedDesigns.push(boughtDesigns[0].actionOptionId);
				} else {
					displayedDesigns.push(boughtDesigns[1].actionOptionId);
				}
			}	
		} else {
			/* 3+ designs bought */
			for (let i = 0; i < 3; i++) {
				displayedDesigns.push(boughtDesigns[boughtDesigns.length - 1 - i].actionOptionId);
			}
		}
	} else {
		displayedDesigns = ['start_01', 'start_01', 'start_01'];
	}
	return displayedDesigns;
}

/**
 * Check if an action is available
 * @param {object} actionData
 * @param {object} gameData 
 * @returns 
 */
function checkIfActionIsAvailable(actionData, gameData) {
	let isAvailable = true;

	if (actionData.isLocked === true) {
		isAvailable = false;
		if (
			gameData.unlockedActions &&
			gameData.unlockedActions.some((a) => {
				return (a.actionId === actionData.id && !a.actionOptionId);
			})
		) {
			isAvailable = true;
		}
	}

	return isAvailable;
}

/**
 * Check if an action option is available
 * @param {string} actionId 
 * @param {object} option 
 * @param {object} gameData 
 * @returns 
 */
function checkIfActionOptionIsAvailable(actionId, option, gameData)  {
	let isAvailable = true;

	
	if (option.isLocked) {
		/* Check if the action option has been unlocked */
		isAvailable = false; 
		if (
			gameData.unlockedActions &&
			gameData.unlockedActions.some((a) => {
				return (a.actionId === actionId && a.actionOptionId === option.id);
			})
		) {
			isAvailable = true;
		}
	}

	if (isAvailable) {
		/* Check if option is blocked (has been selected previously) */
		if (
			gameData.blockedActions && 
			gameData.blockedActions.some((a) => {
				return (
					a.actionId === actionId &&
					a.actionOptionId === option.id
				);
			})
		) {
			isAvailable = false;
		}	
	}

	if (isAvailable && option.maxTimesSelectable) {
		let numberOfTimesSelected = 0;
		gameData.rounds.forEach((round) => {
			if (
				round.selectedActions &&
				round.selectedActions.some((a) => {
					return (
						a.actionId === actionId &&
						a.actionOptionId === option.id
					);
				})
			) {
				numberOfTimesSelected += 1;
			}
		});

		if (numberOfTimesSelected >= option.maxTimesSelectable) {
			isAvailable = false;
		}
	}

	return isAvailable;
};

/**
 * Check if action option can be selected
 * @param {object} option 
 * @param {object} gameData 
 * @returns 
 */
function checkIfActionOptionCanBeSelected(option, gameData) {
	let canBeSelected = true;
	let feedback = null;

	if (option.conditions && option.conditions.length > 0) {
		option.conditions.forEach((c) => {
			if (!canBeSelected) return;
			if (c.type === 'minimum-budget-stat') {
				if (gameData.rounds && gameData.rounds.length > 0) {
					const prevRoundData = gameData.rounds.find((round) => {
						return round.roundNumber === gameData.roundNumber - 1;
					});
					if (prevRoundData && prevRoundData.actualBudget && prevRoundData.actualBudget[c.statId]) {
						if (prevRoundData.actualBudget[c.statId] < c.minVal) {
							canBeSelected = false;
							feedback = {
								condition: c,
								value: addThousandSeparator(prevRoundData.actualBudget[c.statId]),
								required: addThousandSeparator(c.minVal)
							};
						}
					} else {
						canBeSelected = false;	
					}
				} else {
					canBeSelected = false;
				}
			}
		});
	}

	return {canBeSelected, feedback};
}

/**
 * Check if an action has been selected in a previous round
 * @param {string} actionId 
 * @param {string} actionOptionId 
 * @param {object} gameData 
 * @returns 
 */
function checkIfActionHasBeenSelectedInPrevRounds(actionId, actionOptionId, gameData) {
	let hasBeenPrevSelected = false;

	if (gameData.rounds && gameData.rounds.length > 0) {
		if (gameData.rounds.some((round) => {
			return (
				round.selectedActions.some((a) => {
					return (a.actionId === actionId && a.actionOptionId === actionOptionId);
				})
			);
		})) {
			hasBeenPrevSelected = true;
		}
	}

	return hasBeenPrevSelected;
}

/**
 * Get new partner buy in price
 * @param {object} gameData 
 * @returns 
 */
function getNewPartnerBuyInPrice(gameData)  {
	let price = null;

	const prevRoundData = gameData.rounds.find((round) => {
		return round.roundNumber === gameData.roundNumber - 1;
	});
	if (prevRoundData && prevRoundData.actualBudget) {
		const prevRoundTurnover = prevRoundData.actualBudget['turnover'];
		if (prevRoundTurnover) {
			price = Math.floor(0.75 * prevRoundTurnover);
		}
	}

	return price;
}


export {
	getDefaultGameData,
	getDisplayedDesigns,
	checkIfActionIsAvailable,
	checkIfActionOptionIsAvailable,
	checkIfActionOptionCanBeSelected,
	checkIfActionHasBeenSelectedInPrevRounds,
	getNewPartnerBuyInPrice
};