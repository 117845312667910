import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import './selected-actions-counter.scss';

const SelectedActionsCounter = ({gameData}) => {
	/* Number of selected actions */
	const numberOfSelectedActions = gameData.selectedActions.length; 


	return (
		<div className="SelectedActionsCounter">
			<div className="SelectedActionsCounter-wrap">
				{Array(appConfig.actionsToSelectPerRound).fill().map((_, i) => {
					const isSelected = i < numberOfSelectedActions;
					return (
						<div key={i} className='SelectedActionsCounter-action'>
							<div className={'SelectedActionsCounter-checkMark' + 
								(isSelected ? ' selected' : ' unselected')}/>
						</div>
					);
				})}
			</div>
		</div>
	);
};


SelectedActionsCounter.propTypes = {
	gameData: PropTypes.object.isRequired,
};


export default SelectedActionsCounter;