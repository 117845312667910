const dictionaryData = [
	{
		id: 'budget',
		title: 'Budget',
		description: `Et budget er jeres bedste bud på kommende indtægter og udgifter.
			<br /><br />Hvis I trykker på <span class="plus"></span> kan I se detaljerne i jeres budget.`
	},
	{
		id: 'turnover',
		title: 'Omsætning',
		description: 'Omsætningen består af alle virksomhedens indtægter i en given periode'
	},
	{
		id: 'expenses',
		title: 'Omkostninger',
		description: 'Omkostninger består af alle virksomhedens udgifter i en given periode'
	},
	{
		id: 'fixed-expenses',
		title: 'Faste udgifter',
		description: 'Faste udgifter, er de udgifter, som du ved kommer hver måned. Det kan være husleje, internet, forsikringer og andet, '
	},
	{
		id: 'variable-expenses',
		title: 'Variable udgifter',
		description: 'Variable udgifter ændrer sig alt efter hvor meget I sælger. Det kan være udgifter til emballage, indkøb transport.'
	},
	{
		id: 'profit',
		title: 'Overskud',
		description: 'Overskuddet er forskellen mellem omsætningen og alle virksomhedens udgifter. Spillet handler om at få så meget overskud som muligt.'
	},
	{
		id: 'liquidity',
		title: 'Likviditet',
		description: 'Likviditet er de penge I har stående på kontoen som I kan bruge af.'
	},
	{
		id: 'stock',
		title: 'Lager',
		description: 'Lageret viser antallet af t-shirts, som I har produceret, men ikke solgt endnu'
	},
	{
		id: 'prod-capacity',
		title: 'Produktionskapacitet',
		description: 'Hvor mange t-shirts I kan nå at lave print på pr kvartal.'
	},
	{
		id: 'purchase',
		title: 'Indkøb',
		description: 'Hvor mange t-shirts uden tryk I indkøber i dette kvartal.'
	},
	{
		id: 'purchase-price',
		title: 'Indkøbspris',
		description: 'Indkøbspris, er den pris, som I betaler for de t-shirts uden tryk I skal lave tryk på og sælge videre.'
	},
	{
		id: 'prod-price',
		title: 'Produktionspris',
		description: 'Produktionsprisen er alle de omkostninger, som I har til blæk og slid på maskiner. Prisen er delt ud på prisen pr t-shirt.'
	},
	{
		id: 'financing',
		title: 'Finansiering',
		description: 'Finansiering betyder at skaffe penge til virksomheden, som ikke kommer fra virksomhedens salg. I spillet kan I tage banklån og tage en partner ind i firmaet.'
	},
	{
		id: 'accounting',
		title: 'Regnskab',
		description: 'Et regnskab er en opgørelse over alle jeres udgifter og indtæger i en bestemt periode. '
	},
	{
		id: 'design-quality',
		title: 'Design og kvalitet',
		description: 'Jo lækrere t-shirt kvalitet, og jo federe logo der er på t-shirtsne, jo mere sandsynligt er det at nogen køber dem. I spillet bliver de to ting lagt sammen til en score mellem 1-10. '
	},
	{
		id: 'dict-customers',
		title: 'Kunder pr kvartal',
		description: `Kunder pr. kvartal fortæller hvor mange kunder der kommer ind i butikken i et kvartal. 
			<br />I har meget trofaste kunder, så en stor del af de kunder der kommer i butikken et kvartal, vil komme igen næste kvartal. I kan få flere kunder ved at lave markedsføring.`
	},
	{
		id: 'dict-buying-customers',
		title: 'Antal der køber',
		description: `Viser hvor mange af de kunder, der kommer i butikken, som køber noget.
			<br />Hvis I hæver kvaliteten eller butiksoplevelsen eller sænker prisen, vil flere kunder købe noget.`
	}
];

module.exports = {
	dictionaryData
}