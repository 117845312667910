import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { sortArrayByProperty } from 'helpers/array-helper';
import { statsUiTexts } from 'data/ui-texts';
import { localizationData } from 'data/localization-data';
import { roundsData } from 'data/rounds-data';
import DatePicker, { registerLocale } from 'react-datepicker';
import Button from 'components/ui/button/button';
import DownloadStats from 'components/ui/download-stats/download-stats';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './stats.scss';

const Stats = ({stats, handleLogout}) => {

	/* Localize dates */
	registerLocale('da', {
		localize: {
			month: (n) => {return localizationData.months[n];},
			day: (n) => {return localizationData.days[n];}
		}, 
		formatLong:{
			date: () => {return 'yyyy-mm-dd';}
		} 
	});

	/* Get earliest creation date */
	const firstDate = (stats.length > 0 
		? dayjs(sortArrayByProperty(stats, 'created', 'ASC')[0].created).format('YYYYMMDD')
		: dayjs(new Date()).format('YYYYMMDD')
	);

	/* Start and end dates */
	const [startDate, setStartDate] = useState(firstDate);
	const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYYMMDD'));

	/* Filter by dates */ 
	const filteredStats = stats.filter((s) => {
		return (
			s.hasOwnProperty('created') && 
			s.created >= startDate &&
			s.created <= endDate
		);
	});

	/* Get total stats */
	const totalStarted = filteredStats.length;
	const totalOngoing = filteredStats.filter((s) => {
		return (s.roundNumber >= 2 && s.roundNumber <= roundsData.length);
	}).length;
	const totalCompleted = filteredStats.filter((s) => {return (s.roundNumber === roundsData.length + 1);}).length;
	const totalCompletedPercent = (totalStarted > 0
		? Math.round(((totalCompleted / totalStarted) * 100.))
		: 0
	);

	return (
		<div className="Stats">
			<Button classes={['logout']} text={statsUiTexts.logout} onClick={() => {handleLogout();}} />
			<div className="Stats-title">{statsUiTexts.statsTitle}</div>
			<div className="Stats-period">
				<DatePicker
					disabled={false}
					selected={startDate ? dayjs(startDate).toDate() : new Date()}
					onChange={(date) => {setStartDate(dayjs(date).format('YYYYMMDD'));}}
					locale={'da'}
					dateFormat="dd/MM-yyyy"
					placeholderText="dd/MM-yyyy"
				/>
				<div className="Stats-periodTo"><span>til</span></div>
				<DatePicker
					disabled={false}
					selected={endDate ? dayjs(endDate).toDate() : new Date()}
					onChange={(date) => {setEndDate(dayjs(date).format('YYYYMMDD'));}}
					locale={'da'}
					dateFormat="dd/MM-yyyy"
					placeholderText="dd/MM-yyyy"
				/>
			</div>

			{/* Genereal stats */}
			<div className="Stats-section">
				<div className="Stats-sectionRow">
					<span>{statsUiTexts.gamesStarted}:</span> <span>{totalStarted}</span>
				</div>
				<div className="Stats-sectionRow">
					<span>{statsUiTexts.gamesOngoing}:</span> <span>{totalOngoing}</span>
				</div>
				<div className="Stats-sectionRow">
					<span>{statsUiTexts.gamesFinished}:</span> <span>{totalCompleted} ({totalCompletedPercent}%)</span>
				</div>
			</div>

			{/* Download btn */}
			<div className="Stats-downloadBtn">
				<DownloadStats players={stats} />
			</div>
		</div>
	);
};

Stats.propTypes = {
	stats: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Stats;