import {statsUiTexts} from 'data/ui-texts';
import {roundsData} from 'data/rounds-data';

function getPlayerStatsColumns() {
	const playersColumns = [
		{value: 'id', label: statsUiTexts.playerId},
		{value: 'date', label: statsUiTexts.playDate},
		{value: 'lastRound', label: statsUiTexts.lastRound},
	];
	return playersColumns;
}

function getRoundStatsColumns() {
	const roundStatsColumns = [
		{value: 'id', label: statsUiTexts.playerId},
		{value: 'action1', label: statsUiTexts.action + ' 1'},
		{value: 'action2', label: statsUiTexts.action + ' 2'},
		{value: 'action3', label: statsUiTexts.action + ' 3'},
		{value: 'action4', label: statsUiTexts.action + ' 4'},
		{value: 'salesPrice', label: statsUiTexts.salesPrice},
		{value: 'stock', label: statsUiTexts.stock},
		{value: 'purchase', label: statsUiTexts.purchase},
		{value: 'prodCap', label: statsUiTexts.prodCap},
		{value: 'buyingCustomers', label: statsUiTexts.buyingCustomers},
		{value: 'turnover', label: statsUiTexts.turnover},
		{value: 'expenses', label: statsUiTexts.expenses},
		{value: 'profit', label: statsUiTexts.profit},
		{value: 'liquidity', label: statsUiTexts.liquidity}
	];
	return roundStatsColumns;
}

export function getStatsSheets() {
	let sheets = [
		{
			id: 'players', 
			name: statsUiTexts.players, 
			columns: getPlayerStatsColumns(), 
			data: []
		}, 
	];	

	roundsData.forEach((round) => {
		sheets.push({
			id: 'round-' + round.roundNumber,
			name: round.title,
			columns: getRoundStatsColumns(),
			data: []
		});
	});
	return sheets;
}