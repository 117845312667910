const gameData = {
	initialLoan: {
		amount: 80000,
		value: 80000,
		interest: 0.1,
		monthlyPayment: 666.67,
	}
};

module.exports = {
	gameData
};