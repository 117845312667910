const reportsData = [
	{
		id: 'bankruptcy1Loan',
		text: `I har en negativ likviditet på %deficit% kr. Da I ikke mangler så mange penge i forhold til hvor mange penge i omsætter for, har banken valgt at give jer et lån der dækker jeres underskud.`,
		priority: 1,
		points: 6,
		order: 1,
		conditions: [
			{
				type: 'bankruptcy',
				subtype: 'first-loan'
			}
		]
	},
	{
		id: 'bankruptcy1NoLoan',
		text: `I har en negativ likviditet på %deficit% kr. I kunne ikke få et lån af banken der kunne dække jeres underskud. I bliver nødt til at lukke butikken.
			<br /><br />Tryk på nyt spil og prøv igen.`,
		priority: 1,
		points: 6, 
		order: 1,
		conditions: [
			{
				type: 'bankruptcy',
				subtype: 'first-no-loan'
			}
		]
	},
	{
		id: 'bankruptcy2',
		text: `I har en negativ likviditet på %deficit% kr. I kunne ikke få et lån af banken der kunne dække jeres underskud. I bliver nødt til at lukke butikken. 
			<br /><br />Tryk på nyt spil og prøv igen.`,
		priority: 1,
		points: 6,
		order: 1,
		conditions: [
			{
				type: 'bankruptcy',
				subtype: 'second'
			}
		]
	},
	{
		id: 'finalRound',
		text: `Spillet er nu slut. I har drevet jeres butik i 2 år. Jeres samlede overskud for de to år er %totalProfit% kr. I er %partners%, der skal dele overskudet. Det vil sige %totalProfitPerPartner% kr til hver, ud over jeres løn.
			<br /><br />Klik lidt rundt og kig på jeres tal. Kan I regne ud, hvad I gjorde rigtigt eller forkert? 
			<br /><br />Tryk på nyt spil for at prøve igen.`,
		priority: 1,
		points: 6,
		order: 2,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 8
			}
		]
	},
	{
		id: 'notEnoughTshirts',
		text: `I købte ikke nok t-shirts til at dække hvor mange I solgte. I måtte indkøbe %extraTshirts% til %extraPrice% kr.`,
		priority: 2,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'notEnoughTshirts'
			}
		]
	},
	{
		id: 'notEnoughtProdCap',
		text: `Jeres produktion kunne ikke følge med salget. I gik glip af %missedSales% salg og dermed %missedEarnings% kr.`,
		text2: '<br />Måske skulle I hyre noget hjælp på værkstedet?',
		priority: 2,
		points: 2,
		order: 2,
		conditions: [
			{
				type: 'notEnoughtProdCap'
			}
		]
	},
	{
		id: 'positiveTurnover1',
		text: `Et kvartal er gået. Jeres omsætning er steget med %turnoverChange% %. Jeres overskud endte på %profit% kr. Jeres likviditet er nu %liquidity% kr.`,
		priority: 2,
		points: 2,
		order: 1,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 1,
			}, 
			{
				type: 'turnover-change',
				subtype: 'positive'
			}
		]
	},
	{
		id: 'positiveTurnover2',
		text: `Endnu et kvartal er gået. Jeres omsætning er steget med %turnoverChange% %. Jeres overskud endte på %profit% kr. Jeres likviditet er nu %liquidity% kr.`,
		priority: 2,
		points: 2,
		order: 1,
		conditions: [
			{
				type: 'min-round',
				roundNumber: 2,
			}, 
			{
				type: 'turnover-change',
				subtype: 'positive'
			}
		]
	},
	{
		id: 'negativeTurnover1',
		text: `Et kvartal er gået. Jeres omsætning er faldet med %turnoverChange% %. Jeres overskud endte på %profit% kr. Jeres likviditet er nu %liquidity% kr.`,
		priority: 2,
		points: 2,
		order: 1,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 1,
			}, 
			{
				type: 'turnover-change',
				subtype: 'negative'
			}
		]
	},
	{
		id: 'negativeTurnover2',
		text: `Endnu et kvartal er gået. Jeres omsætning er faldet med %turnoverChange% %. Jeres overskud endte på %profit% kr. Jeres likviditet er nu %liquidity% kr.`,
		priority: 2,
		points: 2,
		order: 1,
		conditions: [
			{
				type: 'min-round',
				roundNumber: 2,
			}, 
			{
				type: 'turnover-change',
				subtype: 'negative'
			}
		]
	},
	{
		id: 'unexpectedTurnover1',
		text: `Der har været godt gang i salget, så vi har solgt meget bedre end vi havde budgeteret med! Husk at se på regnskabet efter hvert kvartal så I har overblik over hvordan det rent faktisk gik.`,
		priority: 2,
		points: 2,
		order: 2,
		conditions: [
			{
				type: 'unexpected-turnover',
				subtype: 'positive',
				limitPercent: 10
			}
		]
	},
	{
		id: 'unexpectedTurnover2',
		text: `Det er gået dårligere end forventet med salget. Vi nåede ikke salget fra vores budget. Husk at se på regnskabet efter hvert kvartal så I har overblik over hvordan det rent faktisk gik.`,
		priority: 2,
		points: 2,
		order: 2,
		conditions: [
			{
				type: 'unexpected-turnover',
				subtype: 'negative',
				limitPercent: -10
			}
		]
	},
	{
		id: 'onlineMarketing',
		text: `Jeres kampagne på sociale medier kører godt. I fik %newCustomers% flere kunder i dette kvartal. Det er en vækst på %newCustomersPercent% %.`,
		priority: 3,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'action-option',
				actionId: 'marketing',
				actionOptionId: 'marketing-d'
			}
		]
	},
	{
		id: 'localNewspaper',
		text: `Nogen på lokalavisen er blevet opmærksomme på jeres butik og vil gerne skrive en historie om jer.`,
		priority: 5,
		points: 1,
		order: 3,
		conditions: [
			{
				type: 'not-repeatable'
			}
		]
	},
	{
		id: 'localBands',
		text: `Jeres møde på spillestedet gik fantastisk. I har fået kontakt til bands der meget gerne vil samarbejde.`,
		priority: 3,
		points: 1,
		order: 3,
		conditions: [
			{
				type: 'action-option',
				actionId: 'innovation',
				actionOptionId: 'innovation-a'
			}
		]
	},
	{
		id: 'japan',
		text: `Det var en fantastisk tur til Japan. I mødte en lokal kunstner som laver Kawaii art, som I tror virkeligt vil sælge godt!`,
		priority: 3,
		points: 1,
		order: 3,
		conditions: [
			{
				type: 'action-option',
				actionId: 'innovation',
				actionOptionId: 'innovation-b'
			}
		]
	},
	{
		id: 'artForums',
		text: `Der er en trend på flere art sites med meget farverige og psykedeliske motiver. I skriver med nogle kunstnere der gerne vil samarbejde.`,
		priority: 3,
		points: 1,
		order: 3,
		conditions: [
			{
				type: 'action-option',
				actionId: 'innovation',
				actionOptionId: 'innovation-c'
			}
		]
	},
	{
		id: 'metal',
		text: `Der er stor interesse for jeres butik til mødet med spillestedet. I er meget velkommen til at lave en bod til festivallen, og flere bands vil også gerne lave et samarbejde.`,
		priority: 3,
		points: 2,
		order: 3,
		conditions: [
			{
				type: 'action-option',
				actionId: 'innovation',
				actionOptionId: 'innovation-d'
			}
		]
	},
	{
		id: 'productionTechniques',
		text: `I har fundet ud af at man kan printe direkte på t-shirts i stedet for at overføre det med varmeprint. Det vil spare jer tid og give bedre kvalitet. Men maskinen er dyr.`,
		priority: 3,
		points: 2,
		order: 3,
		conditions: [
			{
				type: 'action-option',
				actionId: 'innovation',
				actionOptionId: 'innovation-f'
			}
		]
	},
	{
		id: 'round4',
		text: `Jeres butik tiltrækker opmærksomhed. Nogle lokale street artists har tilbudt at I trykker nogle af deres værker.`,
		priority: 4,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 3
			}
		]
	},
	{
		id: 'round5',
		text: `I har set vildt mange gyserfilm på det seneste og er meget optaget af æstetikken i det. Måske kunne man lave noget tryk med det på?`,
		priority: 4,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 4
			}
		]
	},
	{
		id: 'round6',
		text: `Jeres butik tiltrækker opmærksomhed fra de helt store stjerner. I får tilbudt tryk fra verdensstjerner!`,
		priority: 4,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'round-finished',
				roundNumber: 5
			}
		]
	},
	{
		id: 'streetEvent',
		text: `Jeres gadeevent gik godt. Nu skal vi rykke på den øgede opmærksomhed!`,
		priority: 4,
		points: 1,
		order: 2,
		conditions: [
			{
				type: 'action-option',
				actionId: 'marketing',
				actionOptionId: 'marketing-a'
			}
		]
	}
];

module.exports = {
	reportsData
};