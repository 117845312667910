import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import GameController from './game/game-controller';
import ImageLoader from './ui/image-loader/image-loader';
import PopupConfirmCookies from './ui/popup-confirm-cookies/popup-confirm-cookies';
import StatsLoginController from './stats/stats-login-controller';
import './app.scss';

const App = () => {
	/*  Font size */
	const [fontSize, setFontSize] = useState(null);

	/* Cookies */
	const [cookiesAccepted, setCookiesAccepted] = useState(false);
	const [cookiePopupType, setCookiePopupType] = useState(null);


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Check fromat */
		checkFormat();
		window.addEventListener('resize', checkFormat);
		if (window.screen && window.screen.orientation) {
			window.screen.orientation.addEventListener('change', checkFormat);
		}

		/* Check cookie consent */
		const gameDataFromCookie = getCookie(appConfig.cookieConsentCookieName);
		if (gameDataFromCookie && gameDataFromCookie.length > 0) {
			setCookiesAccepted(true);
		}
		/* Initialize firebase */
		firebaseInit();
	}, []);

	/**
	 * Check window format
	 */
	const checkFormat = () => {
		const AppDiv = document.getElementById('App');

		if (AppDiv) {
			const width = AppDiv.offsetWidth;
			const height = AppDiv.offsetHeight;
			const ratio = width / height;
			if (ratio > (1920 / 1080.)) {
				setFontSize(18 * (height / 1080.));
			} else {
				setFontSize(null);
			}
		}	
	};

	/**
	 * Accept / deny cookies
	 * @param {bool} isAccepted 
	 */
	const toggleAcceptCookies = (isAccepted) => {
		if (isAccepted) {
			setCookie(appConfig.cookieConsentCookieName, 'ok');
			setCookiesAccepted(true);
		} else {
			deleteCookie(appConfig.cookieConsentCookieName);
			deleteCookie(appConfig.gameDataCookieName);
			setCookiesAccepted(false);
		}
		if (cookiePopupType === 'default' || !isAccepted) {
			/* Cookie popup was opened by clicking cookie icon */
			setCookiePopupType(null); 
		}
	};

	const style = (fontSize ? {fontSize : fontSize} : null);

	/* Stats section */
	if (window.location.pathname.toLowerCase().includes('stats')) {
		return <div id="app" className="App App--stats"><StatsLoginController /></div>;
	}


	return (
		<div className="App" id="App" style={style}>
			<div className={'App-wrap' + (style ? ' wide' : '')}>
				<ImageLoader />
				<GameController 
					cookiesAccepted={cookiesAccepted} 
					cookiePopupType={cookiePopupType}
					setCookiePopupType={setCookiePopupType} 
				/>
				{cookiePopupType && 
					<PopupConfirmCookies 
						toggleAcceptCookies={toggleAcceptCookies}
						cookiePopupType={cookiePopupType}
						setCookiePopupType={setCookiePopupType}
					/>
				}
			</div>
		</div>
	);
};

export default App;
