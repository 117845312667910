const roundsData = [
	{
		roundNumber: 1,
		title: 'Runde 1',
		unlocksActions: []
	},
	{
		roundNumber: 2,
		title: 'Runde 2',
		unlocksActions: []
	},
	{
		roundNumber: 3,
		title: 'Runde 3',
		unlocksActions: []
	},
	{
		roundNumber: 4,
		title: 'Runde 4',
		unlocksActions: [
			{actionId: 'innovation', actionOptionId: 'innovation-d'},
			{actionId: 'new-designs', actionOptionId: 'streetart_01'},
			{actionId: 'new-designs', actionOptionId: 'streetart_02'},
			{actionId: 'new-designs', actionOptionId: 'streetart_03'}
		]
	},
	{
		roundNumber: 5,
		title: 'Runde 5',
		unlocksActions: [
			{actionId: 'new-designs', actionOptionId: 'horrorart_01'},
			{actionId: 'new-designs', actionOptionId: 'horrorart_02'},
			{actionId: 'new-designs', actionOptionId: 'horrorart_03'}
		]
	},
	{
		roundNumber: 6,
		title: 'Runde 6',
		unlocksActions: [
			{actionId: 'new-designs', actionOptionId: 'stjernekunst_01'},
			{actionId: 'new-designs', actionOptionId: 'gueststar_01'},
		]
	},
	{
		roundNumber: 7,
		title: 'Runde 7',
		unlocksActions: []
	},
	{
		roundNumber: 8,
		title: 'Runde 8',
		unlocksActions: []
	}
];

module.exports = {
	roundsData
};