let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let playersDbName = 'players';
let newRoundAnimationDurationSec = 1; 

if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-tshirt-test.web.app';
	if (env === 'demo') {
		gameUrl = 'cgl-tshirt-demo.web.app';
		playersDbName = 'players-demo';	
	}
}
if (env === 'production') {
	gameUrl = 'cgl-tshirt-production.web.app';
	newRoundAnimationDurationSec = 5;
}



const appConfig = {
	env,
	gameUrl,
	playersDbName,
	name: 'TheTshop',
	cookieConsentCookieName: 'tshirt_cookie-consent-data',
	gameDataCookieName: 'tshirt_game-data',
	actionsToSelectPerRound: 3,
	extraPurchasePrice: 200,
	newRoundAnimationDurationSec,
	reportMaxPoints: 6,
	videoId: '7FWRn1knyR4'
};

export default appConfig;