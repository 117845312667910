const imagesData = [
	'logo.svg',

	'action-options/marketing-a.svg',
	'action-options/marketing-c.svg',
	'action-options/marketing-c.svg',
	'action-options/offer-campaign-a.svg',
	'action-options/offer-campaign-b-c.svg',

	'actions/financing.svg',
	'actions/hardware.svg',
	'actions/innovation.svg',
	'actions/marketing.svg',
	'actions/offer-campaign.svg',
	'actions/shop-interior-design.svg',
	'actions/staff-1.svg',
	'actions/staff-2.svg',
	'actions/tshirt-designs.svg',
	'actions/tshirt-supplier.svg',
	'actions/upskilling.svg',

	'animations/between-rounds.gif',

	'backgrounds/background-office-character.png',
	'backgrounds/background-office.png',
	'backgrounds/background-page-1.svg',
	'backgrounds/background-page-2.svg',
	'backgrounds/background-page.svg',
	'backgrounds/background-production-character.png',
	'backgrounds/background-production.png',
	'backgrounds/background-shop-character.png',
	'backgrounds/background-shop.png',
	'backgrounds/background-splash-characters.png',
	'backgrounds/background-splash-shop.png',

	'designs/friend_01.png',
	'designs/friend_02.png',
	'designs/graffiti_01.png',
	'designs/graffiti_02.png',
	'designs/gueststar_01.png',
	'designs/horrorart_01.png',
	'designs/horrorart_02.png',
	'designs/horrorart_03.png',
	'designs/indieband_01.png',
	'designs/indieband_02.png',
	'designs/indieband_03.png',
	'designs/indieband_04.png',
	'designs/kawaii_01.png',
	'designs/kawaii_02.png',
	'designs/kawaii_03.png',
	'designs/kawaii_04.png',
	'designs/kawaii_05.png',
	'designs/metalband_01.png',
	'designs/metalband_02.png',
	'designs/metalband_03.png',
	'designs/psychedelic_01.png',
	'designs/psychedelic_02.png',
	'designs/psychedelic_03.png',
	'designs/start_01.png',
	'designs/stjernekunst_01.png',
	'designs/streetart_01.png',
	'designs/streetart_02.png',
	'designs/streetart_03.png',

	'icons/icon-arrow-down.svg',
	'icons/icon-check-blue.svg',
	'icons/icon-check.svg',
	'icons/icon-close.svg',
	'icons/icon-cookie.svg',
	'icons/icon-info-button.svg',
	'icons/icon-info-button1.svg',
	'icons/icon-info.svg',
	'icons/icon-loading-2.svg',
	'icons/icon-loading.svg',
	'icons/icon-minus.svg',
	'icons/icon-office.svg',
	'icons/icon-plus.svg',
	'icons/icon-round-current.svg',
	'icons/icon-round-future.svg',
	'icons/icon-round-past.svg'

];

module.exports = {
	imagesData
};