
import React from 'react';
import PropTypes from 'prop-types';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Graph from 'components/ui/graph/graph';
import {roundsData} from 'data/rounds-data';
import {getInitialStats, calculateCurrentStats, calculateBudget} from 'helpers/stat-and-budget-helpers';
import './area-page-history.scss';

const AreaPageHistory = ({gameData, predictedBudget}) => {
	/* Initial data (stats and budget without any selected actions) */
	const initialBasicStats = getInitialStats();
	const initialStats = calculateCurrentStats({stats: initialBasicStats, selectedActions: []});
	const initialPredictedBudget = calculateBudget(initialStats);
	const data = [
		{id: 'turnover', values: [{round: 0, value: initialPredictedBudget['turnover']}]},
		{id: 'expenses', values: [{round: 0, value: initialPredictedBudget['total-expenses']}]},
		{id: 'profit', values: [{round: 0, value: initialPredictedBudget['profit']}]}
	];

	/* Previous rounds (full lines) */
	gameData.rounds.forEach((round) => {
		data[0].values.push({round: round.roundNumber, value: round.actualBudget['turnover']});
		data[1].values.push({round: round.roundNumber, value: round.actualBudget['total-expenses']});
		data[2].values.push({round: round.roundNumber, value: round.actualBudget['profit']});
	});

	/* Current round (dashed line) */
	if (gameData.roundNumber < roundsData.length) {
		if (gameData.roundNumber === 1) {
			data.push({id: 'turnover-predicted', values: [{round: 0, value: initialPredictedBudget['turnover']}]});
			data.push({
				id: 'expenses-predicted', values: [{round: 0, value: initialPredictedBudget['total-expenses']}]
			});
			data.push({id: 'profit-predicted', values: [{round: 0, value: initialPredictedBudget['profit']}]});
		} else {
			data.push({
				id: 'turnover-predicted', 
				values: [{
					round: gameData.roundNumber - 1, 
					value: gameData.rounds[gameData.rounds.length - 1].actualBudget['turnover']
				}]
			});
			data.push({
				id: 'expenses-predicted', 
				values: [{
					round: gameData.roundNumber - 1, 
					value: gameData.rounds[gameData.rounds.length - 1].actualBudget['total-expenses']
				}]
			});
			data.push({
				id: 'profit-predicted', 
				values: [{
					round: gameData.roundNumber - 1, 
					value: gameData.rounds[gameData.rounds.length - 1].actualBudget['profit']
				}]
			});
		}
		data[3].values.push({round: gameData.roundNumber, value: predictedBudget['turnover']});
		data[4].values.push({round: gameData.roundNumber, value: predictedBudget['total-expenses']});
		data[5].values.push({round: gameData.roundNumber, value: predictedBudget['profit']});
	}
	


	/* Find min / max value of y */
	let yMinVal = 0;
	let yMaxVal = 200000;
	data.forEach((dataGroup) => {
		dataGroup.values.forEach((dataPoint) => {
			if (dataPoint.value > yMaxVal) yMaxVal = dataPoint.value;
			if (dataPoint.value < yMinVal) yMinVal = dataPoint.value;
		});
	});

	

	return (
		<div className="AreaPageHistory">
			<ParentSize>{({ width, height }) => {
				return (
					<Graph 
						width={width} 
						height={height}
						xMaxVal={roundsData.length}
						yMinVal={yMinVal}
						yMaxVal={yMaxVal}
						data={data}
					/>
				);
			}}</ParentSize>
		</div>
	);
};


AreaPageHistory.propTypes = {
	gameData: PropTypes.object.isRequired,
	predictedBudget: PropTypes.object.isRequired
};


export default AreaPageHistory;