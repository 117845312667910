const designActionOptions = [
	{
		id: 'friend_01',
		title: 'Tegning',
		description: 'Jeres vens tegning',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 10000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 2
			},
		]
	},
	{
		id: 'friend_02',
		title: 'Tegning',
		description: 'Jeres vens anden tegning',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 11000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 2
			},
		]
	},
	{
		id: 'graffiti_01',
		title: 'Graffiti',
		description: 'Den lokale graffiti-kunstners hurtige værk',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 11000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 2
			},
		]
	},
	{
		id: 'graffiti_02',
		title: 'Graffiti',
		description: 'Den lokale graffiti-kunstners fede værk',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 15000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		]
	},
	{
		id: 'indie_01',
		title: 'Indieband-logo',
		isLocked: true,
		description: 'Kendt for "Jeg ved en lærkerede" på banjo og vaskebræt.',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 14600,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'indie_02',
		title: 'Indieband-logo',
		isLocked: true,
		description: 'Band med 3 brødre med forskellige mødre.',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 16000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'indie_03',
		title: 'Indieband-logo',
		description: 'Røg ud fra bootcamp i X-factor.',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 15900,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'indie_04',
		title: 'Indieband-logo',
		description: 'Bandet Strawberry Man-Bun.',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 16025,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'psychedelic_01',
		title: 'Psykedelisk mønster',
		isLocked: true,
		description: '"Insidious wallflower" Psykedelisk mønster af en rising star på nettet.',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 30000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'psychedelic_02',
		title: 'Psykedelisk mønster',
		isLocked: true,
		description: '"Sans Titre" Psykedelisk mønster af en rising star på nettet.',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 30000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'psychedelic_03',
		title: 'Psykedelisk mønster',
		description: '"Le Kolour" Psykedelisk mønster af en rising star på nettet.',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 32000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'kawaii_01',
		title: 'Kawaii',
		isLocked: true,
		description: 'Kawaii art af jeres kontakt i Japan',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 33000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'kawaii_02',
		title: 'Kawaii',
		isLocked: true,
		description: 'Kawaii art af jeres kontakt i Japan',
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 30000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'kawaii_03',
		title: 'Kawaii',
		description: 'Kawaii art af jeres kontakt i Japan',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 75000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	},
	{
		id: 'kawaii_04',
		title: 'Kawaii',
		description: 'Kawaii art af jeres kontakt i Japan',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 80000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	},
	{
		id: 'kawaii_05',
		title: 'Kawaii',
		description: 'Kawaii art af jeres kontakt i Japan',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 83000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	},
	{
		id: 'streetart_01',
		title: 'Street art',
		description: 'Betonkunst',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 24500,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'streetart_02',
		title: 'Street art',
		description: 'Edgy artwork',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 35000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'streetart_03',
		title: 'Street art',
		description: 'Det bliver ikke mere street',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 37000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'horrorart_01',
		title: 'Horror art',
		description: 'Monster fedt artwork',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 29000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 3
			},
		],
	},
	{
		id: 'horrorart_02',
		title: 'Horror art',
		description: 'Kommende b-film logo',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 35000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'horrorart_03',
		title: 'Horror art',
		description: 'MunsterFar\'s (alias for Tamarik Vin Skjold) debutværk.',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 77000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	},
	{
		id: 'metalband_01',
		title: 'Metal band',
		description: 'Hypet lokalt metalband',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 36000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'metalband_02',
		title: 'Metal band',
		description: 'Spiller den mærkværdige genre CrabCore',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 39000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'metalband_03',
		title: 'Metal band',
		description: 'Rising star death metal band',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 40000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 4
			},
		],
	},
	{
		id: 'stjernekunst_01',
		title: 'Stjernekunst',
		description: 'Et værk af den internationele kunststjerne Tamarik Vin Skjold',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 85000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	},
	{
		id: 'gueststar_01',
		title: 'Special guest star',
		description: 'Den anonyme kunstner "Glem Mit Alias"',
		isLocked: true,
		effects: [
			{
				type: 'expense',
				expenseType: 'design-expenses',
				expenseTitle: 'Nye designs',
				price: 95000,
			},
			{
				type: 'change-stat',
				statId: 'design-score',
				statValue: 5
			},
		],
	}
];

export default designActionOptions;