import React from 'react';
import PropTypes from 'prop-types';
import {scaleLinear} from '@visx/scale';
import {AxisLeft, AxisBottom} from '@visx/axis';
import {GridColumns} from '@visx/grid';
import {MarkerCircle} from '@visx/marker';
import {LinePath} from '@visx/shape';
import {Group} from '@visx/group';
import {gameUiTexts} from 'data/ui-texts';
import {addThousandSeparator} from 'helpers/math-helper';
import './graph.scss';
/* 
https://airbnb.io/visx/axis 
https://airbnb.io/visx/threshold 
https://airbnb.io/visx/statsplot
*/
const Graph = (props)  => {
	const {
		width, 
		height, 
		yMinVal,
		yMaxVal, 
		xMaxVal,
		data
	} = props;

	/* Margin */
	const margin = { 
		top: 0.015 * width, 
		right: 0.01 * width, 
		bottom: 0.035 * width,
		left: 0.1 * width
	};

	/* X axis scale */
	const ticSizeX = 1;
	const numberOfTicsX = xMaxVal / ticSizeX;

	/* Y axis scale */
	const yMaxNumber = Math.ceil(yMaxVal);
	const yMinNumber = Math.floor(yMinVal);
	const offSet = yMaxNumber * 0.2;

	const numberOfTicsY = 10;
	const xScale = scaleLinear({domain: [0, xMaxVal]});
	const yScale = scaleLinear({domain: [yMinNumber, yMaxVal + offSet]});
	
	/* Axis formats */
	const formatXValue = (value) => {return value + '. ' + gameUiTexts.quarter;};
	const formatYValue = (value) => {
		const newValue = addThousandSeparator(value);
		return newValue;
	};

	/* Get data values */
	const getX = (d) => {return d.round;};
	const getY = (d) => {return d.value;};

	/* Bounds */
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	xScale.range([0, xMax]);
	yScale.range([yMax, 0]);

	/* Graph too small */
	if (width < 10) return null;

	return (
		<div className="Graph">
			<svg width={width} height={height}>
				{/* Grid, axes and labels */}
				<Group left={margin.left} top={margin.top}>
					
					<GridColumns 
						className="Graph-grid" 
						width={xMax} 
						height={yMax} 
						numTicks={numberOfTicsX}  // updated
						scale={xScale}
					/>
					<line className="Graph-topLine" x1="0" y1="0" x2={width} y2="0" /><line />
					<AxisBottom 
						axisClassName="Graph-axis bottom"
						tickClassName="Graph-tic bottom"
						top={yMax} 
						scale={xScale} 
						numTicks={numberOfTicsX} 
						hideZero={true}
						hideTicks={true}
						tickLength={0}
						tickFormat={formatXValue}
					/>
					<AxisLeft 
						axisClassName="Graph-axis left"
						tickClassName="Graph-tic left"
						scale={yScale} 
						numTicks={numberOfTicsY} 
						// hideZero={true}
						hideTicks={true}
						tickLength={0}
						tickFormat={formatYValue}
					/>
					
					<circle 
						className="Graph-labelIcon turnover" 
						cx={0.02 * width} 
						cy={0.02 * width} 
						r={0.008 * width} 
					/>
					<text className="Graph-labelText turnover" x={0.04 * width} y={0.02 * width}>
						{gameUiTexts.turnover}
					</text>
					
					<circle 
						className="Graph-labelIcon expenses" 
						cx={0.02 * width} 
						cy={0.04 * width} 
						r={0.008 * width} 
					/>
					<text 
						className="Graph-labelText expenses" 
						x={0.04 * width} 
						y={0.04 * width}
						r={0.008 * width} 
					>
						{gameUiTexts.expenses}
					</text>
					
					<circle className="Graph-labelIcon profit" cx={0.02 * width} cy={0.06 * width} r={0.008 * width} />
					<text className="Graph-labelText profit" x={0.04 * width} y={0.06 * width}>
						{gameUiTexts.profit}
					</text>
					
				</Group>

				{/* Data */}
				<Group left={margin.left} top={margin.top} height={yMax}>
					{/* Markers */}
					<MarkerCircle 
						id="marker-turnover" 
						className="Graph-marker turnover" 
						size={2} 
						refX={2.5}
					/>
					<MarkerCircle 
						id="marker-expenses" 
						className="Graph-marker expenses" 
						size={2} 
						refX={2.5}
					/>
					<MarkerCircle 
						id="marker-profit" 
						className="Graph-marker profit" 
						size={2} 
						refX={2.5}
					/>

					{/* Turnover */}
					<Group>
						<LinePath
							className="Graph-line turnover"
							data={data[0].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerStart="url(#marker-turnover)"
							markerMid="url(#marker-turnover)"
							markerEnd="url(#marker-turnover)"
						/>
					</Group>

					{/* Turnover (current round) */}
					{/* {data.length > 3 && <Group>
						<LinePath
							className="Graph-line predicted-turnover"
							data={data[3].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerEnd="url(#marker-turnover)"
						/>
					</Group>} */}


					{/* Expenses */}
					<Group>
						<LinePath
							className="Graph-line expenses"
							data={data[1].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerStart="url(#marker-expenses)"
							markerMid="url(#marker-expenses)"
							markerEnd="url(#marker-expenses)"
						/>
					</Group>

					{/* Expenses (current round) */}
					{/* {data.length > 4 && <Group>
						<LinePath
							className="Graph-line predicted-expenses"
							data={data[4].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerEnd="url(#marker-expenses)"
						/>
					</Group>} */}

					{/* Profit */}
					<Group>
						<LinePath
							className="Graph-line profit"
							data={data[2].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerStart="url(#marker-profit)"
							markerMid="url(#marker-profit)"
							markerEnd="url(#marker-profit)"
						/>
					</Group>

					{/* Profit (current round) */}
					{/* {data.length > 5 && <Group>
						<LinePath
							className="Graph-line predicted-profit"
							data={data[5].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale(getY(d)) ?? 0;}}
							markerEnd="url(#marker-profit)"
						/>
					</Group>} */}

				</Group>
			</svg>
		</div>
	);
};

Graph.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	xMaxVal: PropTypes.number.isRequired,
	yMinVal: PropTypes.number.isRequired,
	yMaxVal: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired
};
export default Graph;