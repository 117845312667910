import {statsData} from 'data/stats-data';
import {areasData} from 'data/areas-data';
import {roundsData} from 'data/rounds-data';
import {gaussianRandom} from './math-helper';
import {getNewPartnerBuyInPrice} from './game-helper';
import appConfig from 'config/app.config';

/**
 * Get constant stats
 */
export function getConstantStats() {
	let constantStats = {};

	/* Stats with basic initial value */
	statsData.forEach((stat) => {
		if (stat.hasOwnProperty('value')) {
			constantStats[stat.id] = stat.value;
		}
	});

	return constantStats;
};

/**
 * Get initial stats
 */
export function getInitialStats() {
	let initialStats = {};

	/* Stats with basic initial value */
	statsData.forEach((stat) => {
		if (stat.hasOwnProperty('initialValue')) {
			initialStats[stat.id] = stat.initialValue;
		}
	});

	return initialStats;
}

/**
 * Get sales prices
 * Is not allowed to be undefined or 0
 * @param {object} currentStats 
 * @returns 
 */
export function getSalesPrice(currentStats) {
	let salesPrice = currentStats['sales-price'];
	if (currentStats['sales'].length > 0) {
		salesPrice = Math.round(salesPrice - ((salesPrice * currentStats['sales'][0].value) / 3.));
	}
	return salesPrice;
	
}

/**
 * Calculate design expenses
 * @param {array} expenses 
 * @returns 
 */
export function calculateDesignExpenses(expenses) {
	let designExpenses = 0;
	expenses.forEach((expense) => {		
		if (expense.expenseType === 'design-expenses') {
			designExpenses += expense.price;
		}
	});

	return designExpenses;
}

/**
 * Calculate stat: design-quality
 * @param {object} gameData 
 * @returns 
 */
export function calculateDesignQuality(currentStats) {
	const designQuality = currentStats['quality-score'] + currentStats['design-score'];
	return designQuality;
};

/**
 * Calculate stat: number of customers
 * @param {object} currentStats 
 * @returns 
 */
export function calculateNumberOfCustomers(currentStats, percent = 0.5) {
	const predictedCustomers = 
		(0.5 * currentStats['customers-prev-round'])  +
		(percent * currentStats['customers-prev-round'])  +
		100 * currentStats['marketing-effect'];
	return Math.round(predictedCustomers);
};

/**
 * Calculate stat: buying-customers
 * @param {object} currentStats 
 * @returns 
 */
export function calculateBuyingCustomers(currentStats) {
	/* Calculate percent buying customers */
	const designQuality = calculateDesignQuality(currentStats);
	const experience = currentStats['experience'];
	const salesPrice = getSalesPrice(currentStats);

	/* Step 1:
	/* For designQuality + experience = 20, buying customers should be 80 for minPrice and 1 for maxPrice */
	/* Formula: a * ( (designQuality / price) + (0 - (20 / maxPrice)) ) + 1,
	/* where a = 79 / (20 / minPrice) + (0 - (20 / minPrice) */
	/* Adjusted so that buying customers is always 1% for maxPrice */
	const minPrice = 50.;
	const maxPrice = 1000;
	const maxDesignQualityExperience = 20;
	const k1 = (1. - 80.) / (maxDesignQualityExperience * (maxPrice - minPrice));
	const k2 =  1 - (maxPrice * maxDesignQualityExperience * k1);
	let buyingCustomers = k1 * (designQuality + experience) * salesPrice + k2 
		- (k1 * (designQuality + experience) * maxPrice + k2);


	/* Step 2:
	/* If price is > limit1 use different function
	/* f(price = limit1) = value of original function
	/* f(price >= limit2) = 0
	*/
	const limit1 = ((designQuality + experience) / 2.) * 100 + 50;
	const limit2 = ((designQuality + experience) / 2.) * 100 + 150;
	if (salesPrice > limit1) {
		if (salesPrice >= limit2) {
			/* Above limit2 -> 0% buying customers */
			buyingCustomers = 0;
		} else {
			/* Between limit1 and limit2 */
			let x1 = limit1;
			let y1 = k1 * (designQuality + experience) * x1 + k2 - (k1 * (designQuality + experience) * maxPrice + k2);
			let x2 = limit2;
			let y2 = 0;
			const a = (y2 - y1) / (x2 - x1);
			const b = y1 - (a * x1);
			buyingCustomers = (a * salesPrice) + b;
		}
	}

	/* Round value, add boost */
	buyingCustomers = Math.round(buyingCustomers) + currentStats['buying-customers-boost'];
	return buyingCustomers;
}

/**
 * Calculate all current stats (basic + calculated)
 * @param {object} gameData 
 */
export function calculateCurrentStats(gameData) {
	let currentStats = null;
	if (gameData && gameData.stats) {
		/* Basic stats */
		currentStats = {...getConstantStats(), ...JSON.parse(JSON.stringify(gameData.stats))};

		/* Expenses */
		currentStats['expenses'] = [];
		currentStats['loans'] = (gameData.loans ? JSON.parse(JSON.stringify(gameData.loans)) : []);
		currentStats['sales'] = [];

		/* Special stat: boost buying customers */
		currentStats['buying-customers-boost'] = 0;

		/* Stats affected by selected actions */
		if (gameData.selectedActions) {
			const allActions = [];
			areasData.forEach((areaData) => {
				areaData.actions.forEach((actionData) => {
					allActions.push(actionData);
				});
			}); 
			gameData.selectedActions.forEach((selectedAction) => {
				const actionData = allActions.find((a) => {return a.id === selectedAction.actionId;});
				if (actionData) {
					const optionData = actionData.options.find((o) => {
						return o.id === selectedAction.actionOptionId;
					});
					if (optionData && optionData.effects) {
						optionData.effects.forEach((effect) => {
							if (effect.type === 'change-stat') {
								currentStats[effect.statId] = effect.statValue;
							}
							if (effect.type === 'change-stat-selected') {
								currentStats[effect.statId] = selectedAction.value;
							}
							if (effect.type === 'add-to-stat') {
								currentStats[effect.statId] += effect.statValue;
							}
							if (effect.type === 'multiply-stat') {
								currentStats[effect.statId] *= effect.statValue;
							}
							if (effect.type === 'expense') {
								/* New expense */
								if (effect.isPermanent === true) {
									/* Permanent, add to stats */
									currentStats[effect.expenseType] += effect.price;
								}
								currentStats['expenses'].push(effect);	
							}
							if (effect.type === 'loan') {
								currentStats['loans'].push(effect);

								/* New loan, add to liquidity */
								currentStats['liquidity'] += effect.amount;
							}
							if (effect.type === 'new-partner') {
								currentStats['number-of-partners'] += 1;
								/* New partner, add to liquidity */
								const price = getNewPartnerBuyInPrice(gameData);
								if (price) {
									currentStats['liquidity'] += price;
								}
							}
							if (effect.type === 'sale') {
								currentStats['sales'].push(effect);
							}
						});
					}
				}
			});
		}
		
		/* Stats with a calculated value  */
		/* NOTE: order is important! "buying-customers" depends on "customer" */
		currentStats['sales-price'] = (currentStats['sales-price-selected'] 
			?  currentStats['sales-price-selected'] 
			: currentStats['sales-price-base']
		);
		currentStats['purchase'] = (currentStats['purchase-selected'] !== null
			?  currentStats['purchase-selected'] 
			: currentStats['purchase-base']
		);

		currentStats['quality-score'] = currentStats['quality-score-base'];
		currentStats['prod-capacity'] = currentStats['prod-capacity-base'];
		statsData.filter((s) => {return s.isModifier === true;}).forEach((s) => {
			if (s.type === 'add') {
				currentStats[s.modifiesStatId] += (currentStats[s.id] ? currentStats[s.id] : 0);
			}
			if (s.type === 'multiply') {
				currentStats[s.modifiesStatId] *= (currentStats[s.id] ? currentStats[s.id] : 1);
			}
		});

		currentStats['design-expenses'] = calculateDesignExpenses(currentStats['expenses']);
		currentStats['design-quality'] = calculateDesignQuality(currentStats);
		currentStats['customers'] = calculateNumberOfCustomers(currentStats);
		currentStats['buying-customers'] = calculateBuyingCustomers(currentStats);
		
		/* Check range limits */
		for (const prop in currentStats) {
			const statData = statsData.find((s) => {return s.id === prop;});
			if (statData && statData.type === 'range' && statData.maxVal) {
				currentStats[prop] = Math.min(currentStats[prop], statData.maxVal);
			}
		};		
	}

	return currentStats;
}

/**
 * Calculate loan expenses
 * @param {object} stats 
 * @returns 
 */
function calculateLoanExpense(loan) {
	let loanExpense = 0;
	if (loan.value > 0) {
		loanExpense = loan.value * (loan.interest / 4.) + 3 * loan.monthlyPayment;
		loanExpense = Math.min(loanExpense, loan.value);
	}
	return loanExpense;
}

/**
 * Calculate predicted / actual budget
 * @param {object} currentStats 
 */
export function calculateBudget(currentStats, isActualBudget = false) {
	let predictedBudget = {};

	/* Loans */
	let loanRatesAndPayment = 0;
	currentStats['loans'].forEach((loan) => {
		const loanExpense = calculateLoanExpense(loan);
		loanRatesAndPayment += loanExpense;
	});
	predictedBudget['loans'] = Math.floor(loanRatesAndPayment);

	/* Rent and utilities */
	predictedBudget['rent-and-utilities'] = currentStats['rent-and-utilities'];

	/* Website */
	predictedBudget['website'] = currentStats['website'];

	/* Salaries */
	predictedBudget['salaries'] = (currentStats['number-of-owners'] * currentStats['owner-salary']) 
		+ currentStats['additional-salaries'];

	/* Fixed expenses */
	predictedBudget['fixed-expenses'] = 
		predictedBudget['loans'] +
		predictedBudget['rent-and-utilities'] +
		predictedBudget['website'] +
		predictedBudget['salaries'];

	/* Production */
	const numberOfBuyingCustomers = currentStats['customers'] * (currentStats['buying-customers'] / 100.);
	const productionCapacity = currentStats['prod-capacity'];
	const availableBlankTshirts = currentStats['stock'] + currentStats['purchase'];
	const availableProduct = Math.min(productionCapacity, availableBlankTshirts);

	/* Default: Shop CAN produce enough t-shirts for all buying customers */
	let productSold = numberOfBuyingCustomers;
	let extraPurchaseExpense = 0;
	let extraTshirtsBought = 0;
	let missedTshirtSales = 0;
	if (availableProduct < numberOfBuyingCustomers) {
		/* Shop CANNOT produce enough t-shirts */
		if (productionCapacity >= numberOfBuyingCustomers) {
			/* Available product is not limited by production capacity, buy extra blank t-shirts at higher cost */
			extraTshirtsBought = productSold - availableProduct;
			extraPurchaseExpense = (productSold - availableProduct) * appConfig.extraPurchasePrice;
		} else {
			/* Available product is limited by production capacity */
			productSold = productionCapacity;
			missedTshirtSales = numberOfBuyingCustomers - productionCapacity;
			if (productionCapacity > availableBlankTshirts) {
				/* Available product is also limited by available blank t-shirts, by extra */
				extraPurchaseExpense = 
					(productionCapacity - availableBlankTshirts) * appConfig.extraPurchasePrice;
			}
		}
	}
	
	predictedBudget['extra-tshirts-bought'] = Math.round(extraTshirtsBought);
	predictedBudget['missed-tshirt-sales'] = Math.round(missedTshirtSales);
	predictedBudget['product-sold'] = Math.floor(productSold);
	const productionExpenses = 
		currentStats['purchase'] * currentStats['purchase-price'] + 
		extraPurchaseExpense +
		productSold * currentStats['production-price'];
	predictedBudget['production'] = Math.round(productionExpenses);

	/* Turnover */
	const salesPrice = getSalesPrice(currentStats);
	predictedBudget['turnover'] = Math.floor(salesPrice * productSold);

	/* Production was capped by capacity, add small fraction to fixed expensses */
	/* This is to avoid that actual budget is same as predicted budget */
	if (missedTshirtSales > 0 && isActualBudget) {
		predictedBudget['fixed-expenses'] += predictedBudget['rent-and-utilities'] * 0.005;
		predictedBudget['turnover'] -= predictedBudget['turnover'] * 0.005;
	}

	
	/* Marketing and other expenses */
	predictedBudget['marketing'] = 0;
	predictedBudget['other'] = 0;
	if (currentStats.expenses) {
		currentStats.expenses.forEach((expense) => {
			/* Ignore if permanent (already part of currentStats) */
			if (expense.isPermanent === true) return;

			if (expense.expenseType === 'marketing-expenses') {
				predictedBudget['marketing'] += expense.price;
			} else {
				predictedBudget['other'] += expense.price;
			}
		});
	}

	/* Total variable expenses */
	const totalVariableExpenses = predictedBudget['production'] + 
	predictedBudget['marketing'] +
	predictedBudget['other'];
	predictedBudget['variable-expenses'] = Math.round(totalVariableExpenses);

	/* Total expenses */
	predictedBudget['total-expenses'] = Math.floor(
		predictedBudget['fixed-expenses'] + predictedBudget['variable-expenses']
	);

	/* Profit */
	predictedBudget['profit'] = predictedBudget['turnover'] - predictedBudget['total-expenses'];

	/* Liquidity */
	predictedBudget['liquidity'] = currentStats['liquidity'] + predictedBudget['profit'];

	return predictedBudget;
}


/**
 * Calculate round result
 * @param {object} gameData 
 * @param {object} currentStats 
 * @param {object} predictedBudget 
 * @returns 
 */
export function calculateRoundResult(gameData, currentStats) {
	/* Calculate actual number of customers and update stats */
	let newCurrentStats = JSON.parse(JSON.stringify(currentStats));
	const randomPercent = Math.round((gaussianRandom(50, 15, 20, 80) / 100.) * 100) / 100;
	newCurrentStats['customers'] = calculateNumberOfCustomers(newCurrentStats, randomPercent);
	newCurrentStats['buying-customers'] = calculateBuyingCustomers(newCurrentStats);
	
	/* Calculate actual budget */
	const actualBudget = calculateBudget(newCurrentStats, true);

	/* Calculate basic stats for next round */
	const nextRoundBasicStats = JSON.parse(JSON.stringify(gameData.stats));
	for (const prop in nextRoundBasicStats) {
		nextRoundBasicStats[prop] = newCurrentStats[prop];
	};
	nextRoundBasicStats['customers-prev-round'] = newCurrentStats['customers'];

	/* Action effects */
	const unlockedActions = (gameData.unlockedActions  
		? JSON.parse(JSON.stringify(gameData.unlockedActions))
		: []
	);
	const blockedActions = (gameData.blockedActions 
		? JSON.parse(JSON.stringify(gameData.blockedActions))
		: []
	);
	if (gameData.selectedActions) {
		const allActions = [];
		areasData.forEach((areaData) => {
			areaData.actions.forEach((actionData) => {
				allActions.push(actionData);
			});
		}); 
		gameData.selectedActions.forEach((selectedAction) => {
			const actionData = allActions.find((a) => {return a.id === selectedAction.actionId;});
			if (actionData) {
				const optionData = actionData.options.find((o) => {
					return o.id === selectedAction.actionOptionId;
				});
				if (optionData) {
					/* Block the action option (if not already blocked) */
					if (!blockedActions.some((ba) => {
						return (
							ba.actionId === actionData.id && ba.actionOptionId === optionData.id
						);
					})) {
						blockedActions.push({actionId: actionData.id, actionOptionId: optionData.id});
						if (optionData.isRepeatableAfterXRounds || optionData.isRepeatableAfterXRounds === 0) {
							/* Block is temporary */
							blockedActions[blockedActions.length - 1].rounds = optionData.isRepeatableAfterXRounds;
						}
					}

					/* Check if option unlocks / blocks another action type or action option */
					if (optionData.effects) {
						optionData.effects.forEach((effect) => {
							if (effect.type === 'unlock-action-type') {
								if (!unlockedActions.some((a) => {
									return (a.actionId === effect.actionId && !a.actionOptionId);
								})) {
									/* Unlock action type */
									unlockedActions.push({actionId: effect.actionId});
								}
							}
							if (effect.type === 'unlock-action-option') {
								if (!unlockedActions.some((ua) => {
									return (
										ua.actionId === effect.actionId && ua.actionOptionId === effect.actionOptionId
									);
								})) {
									/* Unlock action option */
									unlockedActions.push(
										{actionId: effect.actionId, actionOptionId: effect.actionOptionId}
									);
								}
							}
							if (effect.type === 'lock-action-option') {
								const actionOptionIndex = unlockedActions.findIndex((ua) => {
									return (
										ua.actionId === effect.actionId && 
										ua.actionOptionId === effect.actionOptionId
									);
								});
								if (actionOptionIndex >= 0) {
									/* Lock action option */
									unlockedActions.splice(actionOptionIndex, 1);
								}
							}
							if (effect.type === 'block-action') {
								if (!blockedActions.some((ba) => {
									return (
										ba.actionId === effect.actionId && ba.actionOptionId === effect.actionOptionId
									);
								})) {
									/* Block action */
									blockedActions.push({
										actionId: effect.actionId, actionOptionId: effect.actionOptionId
									});
									if (effect.isRepeatableAfterXRounds || effect.isRepeatableAfterXRounds === 0) {
										/* Block is temporary */
										blockedActions[blockedActions.length - 1].rounds 
											= effect.isRepeatableAfterXRounds;
									}
								}
							}
						});
					}
				}
			}
		});
	}

	/* Production was capped by capacity -> unlock action */
	if (
		actualBudget['missed-tshirt-sales'] > 0 &&
		!unlockedActions.some((a) => {
			return (
				a.actionId === 'hire-helper-production' &&
				!a.actionOptionId
			);
		})) {
		unlockedActions.push({actionId: 'hire-helper-production'});
	}
	

	/* Update unlocked actions */
	const nextRoundData = roundsData.find((r) => {return r.roundNumber === (gameData.roundNumber + 1);});
	if (nextRoundData && nextRoundData.unlocksActions) {
		nextRoundData.unlocksActions.forEach((action) => {
			unlockedActions.push(action);
		});
	}

	/* Update blocked actions */
	for (let i = blockedActions.length - 1; i >= 0; i--) {
		if (blockedActions[i].hasOwnProperty('rounds')) {
			blockedActions[i].rounds -= 1;
			if (blockedActions[i].rounds <= 0) {
				/* Unblock action */
				blockedActions.splice(i, 1);
			}
		}
	}
	
	/* Stock */
	const availableProduct = newCurrentStats['stock'] + newCurrentStats['purchase'];
	const productSold = actualBudget['product-sold'];
	nextRoundBasicStats['stock'] = Math.max(0, Math.floor(availableProduct - productSold));

	/* Liquidity */
	nextRoundBasicStats['liquidity'] = actualBudget['liquidity'];

	/* Update loans */
	let loans = JSON.parse(JSON.stringify(newCurrentStats['loans']));
	loans.forEach((loan) => {
		const loanExpense = calculateLoanExpense(loan);
		loan.value = Math.max(0, Math.round(loan.value - loanExpense));
	});

	/* Sales price and purchase */
	nextRoundBasicStats['sales-price-base'] = newCurrentStats['sales-price'];
	nextRoundBasicStats['sales-price-selected'] = null;
	nextRoundBasicStats['purchase-base'] = newCurrentStats['purchase'];
	nextRoundBasicStats['purchase-selected'] = null;

	/* Supplier */
	if (newCurrentStats['supplier-selected']) {
		nextRoundBasicStats['supplier-base'] = newCurrentStats['supplier-selected'];
		nextRoundBasicStats['supplier-selected'] = null;
	}
	
	/* Design & marketing devaluation */
	nextRoundBasicStats['design-score'] = Math.max(1, nextRoundBasicStats['design-score'] - 1);
	nextRoundBasicStats['marketing-effect'] = Math.max(1, newCurrentStats['marketing-effect'] - 1);

	/* Check for bankruptcy */
	let isFullyBankrupt = false;
	let bankruptcies = gameData.bankruptcies
		?  JSON.parse(JSON.stringify(gameData.bankruptcies))
		: [];

	if (nextRoundBasicStats['liquidity'] < 0) {
		const deficit = nextRoundBasicStats['liquidity'];

		if (bankruptcies.length === 0) {
			/* First bankruptcy. */
			if (Math.abs(deficit) > 0.2 * actualBudget['turnover']) {
				/* Too big to get loan -> full bankruptcy */
				bankruptcies.push({
					isGameOver: true,
					roundNumber: gameData.roundNumber,
					deficit,
				});
				isFullyBankrupt = true;
			} else {
				/* Small enought to get loan */
				const newLoan = {
					amount: Math.abs(deficit),
					value: 1.2 * Math.abs(deficit),
					interest: 0.2,
					monthlyPayment: (1.2 * Math.abs(deficit)) / 9.,
				};
				loans.push(newLoan);
				nextRoundBasicStats['liquidity'] = 0;
				actualBudget['liquidity'] = 0;
				bankruptcies.push({
					isGameOver: false,
					roundNumber: gameData.roundNumber,
					deficit,
				});
			}
		} else {
			/* Second bankcruptcy -> full bankcruptcy */
			bankruptcies.push({
				isGameOver: true,
				roundNumber: gameData.roundNumber,
				deficit,
			});
			isFullyBankrupt = true;
		}
	}

	return {
		isFullyBankrupt,
		bankruptcies,
		randomPercent,
		actualBudget,
		nextRoundBasicStats,
		blockedActions,
		unlockedActions,
		loans
	};
}