import React from 'react';
import {imagesData} from 'data/images-data';
import './image-loader.scss';

const ImageLoader = () => {
	return (
		<div className="ImageLoader">
			{imagesData.map((file, index) => {
				let imgSrc = null;
				try {
					imgSrc = require('../../../assets/images/' + file);
				} catch (error) {
					console.error(error);
				}
				if (!imgSrc) return null;
				
				return <img key={index} src={imgSrc} alt="preload" />;
			})}
		</div>
	);
};

export default ImageLoader;