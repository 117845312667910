import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-confirm-cookies.scss';

const PopupConfirmCookies = ({toggleAcceptCookies, setCookiePopupType}) => {

	/* Hide / show more info */
	const [showMoreInfo, setShowMoreInfo] = useState(false);
 
	return (
		<div className="PopupConfirmCookies">
			<div className="PopupConfirmCookies-content">
				<div className="PopupConfirmCookies-closeBtn" onClick={() => {setCookiePopupType(null);}}/>
				<div className="PopupConfirmCookies-text">
					{renderMarkdown(gameUiTexts.cookieText1)}
					{showMoreInfo && renderMarkdown(gameUiTexts.cookieText2)}
				</div>
				<div 
					className="PopupConfirmCookies-textBtn"
					onClick={() => {setShowMoreInfo(!showMoreInfo);}}
				>
					<span>
						{showMoreInfo ? gameUiTexts.cookieSeeLess : gameUiTexts.cookieSeeMore}
					</span>
				</div>
				<div className="PopupConfirmCookies-buttons">
					<div className="PopupConfirmCookies-button yes">
						<Button 
							text={gameUiTexts.yes} 
							classes={['blue', 'fullHeight']} 
							onClick={() => {toggleAcceptCookies(true);}} 
						/>
					</div>
					<div className="PopupConfirmCookies-button no">
						<Button 
							text={gameUiTexts.no} 
							classes={['yellow', 'fullHeight']} 
							onClick={() => {toggleAcceptCookies(false);}} 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupConfirmCookies.propTypes = {
	toggleAcceptCookies: PropTypes.func.isRequired,
	setCookiePopupType: PropTypes.func.isRequired,
};

export default PopupConfirmCookies;
