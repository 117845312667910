function setCookie(cname, cvalue) {
	let year = new Date();
	year.setTime(year.getTime() + (365 * 24 * 60 * 60 * 1000));

	let expires = 'expires=' + year.toUTCString();

	if (cname === 'tshirt_game-data') {
		year.setTime(year.getTime() + (24 * 60 * 60 * 1000));
		expires = 'expires=' + year.toUTCString();
	}
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function deleteCookie(cname) {
	setCookie(cname, '', 0);
}

export {
	setCookie,
	getCookie,
	deleteCookie
};