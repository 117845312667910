import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import {getDefaultGameData} from 'helpers/game-helper';
import {calculateCurrentStats, calculateBudget, calculateRoundResult} from 'helpers/stat-and-budget-helpers';
import {generateReport} from 'helpers/reports-helper';
import {gameUiTexts} from 'data/ui-texts';
import LoadingPage from 'components/loading-page/loading-page';
import Game from 'components/game/game';
import StoreFront from 'components/store-front/store-front';

class GameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showStoreFront: true,
			animateEnterShop: false,
			isEndingRound: false,
			isConnectedToGame: false,
			playerDbId: null,
			areaId: 'shop',
			selectedActionId: null,
			selectedAreaPageId: null,
			gameData: null,
			popupData: null,
			dictionaryPopupData: null,
		};
		this.timeout = null;
		this.timeout2 = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get default game data */
		let gameData = getDefaultGameData();

		/* Get player db id from cookie */
		const gameDataFromCookie = getCookie(appConfig.gameDataCookieName);
		if (gameDataFromCookie && gameDataFromCookie.length > 0) {
			/* Get game data from database */
			const playerDbId = gameDataFromCookie;
			const db = firebase.firestore();
			db.collection(appConfig.playersDbName).doc(playerDbId).get().then((doc) => {
				if (doc.exists && doc.data()) {
					gameData = Object.assign({}, {...getDefaultGameData(), ...doc.data()});
					this.setState({isLoading: false, playerDbId, gameData});
				} else {
					this.setState({isLoading: false, gameData});		
				}
			}).catch(() => {
				this.setState({isLoading: false, gameData});	
			});
		} else {
			this.setState({isLoading: false, gameData});
		}
	};

	/**
	 * Component updated
	 * @param {object} prevProps 
	 */
	componentDidUpdate = (prevProps) => {
		if (!prevProps.cookiesAccepted && this.props.cookiesAccepted && this.props.cookiePopupType === 'start') {
			/* Player approved cookies, was prompted from clicking "start" -> start game */
			this.props.setCookiePopupType(null);
			this.handleStartGame();
		}
	}; 

	/**
	 * Start game
	 */
	handleStartGame = () => {
		if (!this.props.cookiesAccepted) {
			this.props.setCookiePopupType('start');
			return;
		}
		
		this.setState({animateEnterShop: true}, ()=>{
			/* Start game */
			if (!this.state.playerDbId) {
				/* New game: Add new document in database */
				this.createNewPlayerInDb().then((response) => {
					if (response.status === 'ok') {
						this.timeout = setTimeout(() => {
							this.setState({animateEnterShop: false, showStoreFront: false});
						}, (3400));
					} else {
						console.error(response.error);
					}
				});
			} else {
				/* Continue game */
				this.timeout = setTimeout(() => {
					this.setState({animateEnterShop: false, showStoreFront: false});
				}, (3400));
			}
		});
	};

	/**
	 * Reset game data
	 */
	resetGameData = () => {
		deleteCookie(appConfig.gameDataCookieName);
		window.location.reload();
	};


	/**
	 * Create new player entry in database
	 * @returns {Promise}
	 */
	createNewPlayerInDb = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.playersDbName).add({
				created: dayjs(new Date()).format('YYYYMMDD')
			}).then((docRef) => {
				setCookie(appConfig.gameDataCookieName, docRef.id);
				this.setState({playerDbId: docRef.id}, () => {
					resolve({status: 'ok'});
				});
			}).catch((error) => {
				resolve({status: 'error', error: error});
			});
		});
	};



	/**
	 * Update game data
	 * @param {object} updates 
	 * @returns 
	 */
	updateGameData = (updates, updateDatabase = false) => {
		return new Promise((resolve) => {
			if (updateDatabase) {
				const db = firebase.firestore();
				db.collection(appConfig.playersDbName).doc(this.state.playerDbId).update({...updates}).then(() => {
					const newGameDataObj = Object.assign({}, {...this.state.gameData, ...updates});
					this.setState({gameData: newGameDataObj}, () => {
						resolve({status: 'ok'});
					});
				}).catch((error) => {
					resolve({status: 'error', error: error});
				});
			} else {
				const newGameDataObj = Object.assign({}, {...this.state.gameData, ...updates});
				this.setState({gameData: newGameDataObj}, () => {
					resolve({status: 'ok'});
				});
			}
		});
	};

	/**
	 * Navigate to area
	 * @param {string} areaId 
	 */
	goToArea = (areaId, actionId = null, pageId = null) => {
		this.setState({
			areaId, 
			selectedActionId: actionId,
			selectedAreaPageId: pageId
		});
	};

	/**
	 * Select an action to show its options
	 * @param {string} actionId 
	 */
	selectAction = (actionId) => {
		if (this.state.selectedActionId === actionId) {
			this.setState({selectedActionId: null});
		} else {
			this.setState({
				selectedActionId: actionId,
				selectedAreaPageId: null
			});
		}
	};

	/**
	 * Confirm selected actions
	 */
	confirmSelectedActions = () => {
		if (this.state.isEndingRound) return;

		const numberOfSelectedActions = this.state.gameData.selectedActions.length; 
		if (numberOfSelectedActions < appConfig.actionsToSelectPerRound) {
			/* Player can select more actions */
			let popupText = JSON.parse(JSON.stringify(gameUiTexts.notEnoughtActionsSelected));
			popupText = popupText
				.replace('%availableActions%', appConfig.actionsToSelectPerRound)
				.replace('%selectedActions%', numberOfSelectedActions);
			const popupData = {
				type: 'warning',
				text: popupText,
				buttons: [
					{
						action: this.endCurrentRound,
						parameters: [],
						text: gameUiTexts.yesContinue,
						classes: ['yellow']
					},
					{
						action: this.togglePopup,
						parameters: [null],
						text: gameUiTexts.noCancel,
						classes: ['blue']
					}
				]
			};
			this.togglePopup(popupData);			
		} else {
			/* Max possible actions selected */
			this.endCurrentRound();	
		}
	};

	/**
	 * Implement actions and end current round
	 * @returns 
	 */
	endCurrentRound = () => {
		if (this.state.isEndingRound) return;

		/* End current round */
		this.setState({
			showStoreFront: true,
			isEndingRound: true,
			popupData: null
		}, () => {
			/* Set page to report in office area at the beginning of new round */
			this.goToArea('office', null, 'report');

			/* Get end of round data */
			const gameRounds = (this.state.gameData.rounds 
				? JSON.parse(JSON.stringify(this.state.gameData.rounds)) : []);
			const currentStats = calculateCurrentStats(this.state.gameData);
			const predictedBudget = calculateBudget(currentStats);
			const {
				isFullyBankrupt,
				bankruptcies,
				randomPercent,
				actualBudget,
				nextRoundBasicStats,
				blockedActions,
				unlockedActions,
				loans
			} = calculateRoundResult(this.state.gameData, currentStats);

			/* Add to game rounds */
			gameRounds.push({
				roundNumber: this.state.gameData.roundNumber,
				stats: currentStats,
				loans: loans,
				selectedActions: this.state.gameData.selectedActions,
				predictedBudget: predictedBudget,
				actualBudget: actualBudget,
				randomCustomerPercent: randomPercent
			});

			/* Get report */
			const report = generateReport(gameRounds, bankruptcies);
			gameRounds[gameRounds.length - 1].report = report;

			/* Update game data */
			this.updateGameData({
				isFullyBankrupt,
				roundNumber: (this.state.gameData.roundNumber + 1),
				stats: nextRoundBasicStats,
				loans,
				bankruptcies,
				selectedActions: [],
				rounds: gameRounds,
				unlockedActions: unlockedActions,
				blockedActions,
			}, true).then(() => {
				this.timeout = setTimeout(() => {
					this.setState({animateEnterShop: true}, () => {
						this.timeout = setTimeout(() => {
							this.setState(
								{
									animateEnterShop: false, 
									showStoreFront: false, 
									isEndingRound: false
								}
							);
						}, (3400));
					});
				}, (appConfig.newRoundAnimationDurationSec * 1000));
			});
		});
	};

	/**
	 * Go to page
	 * @param {string} pageId 
	 */
	selectPageId = (pageId) => {
		this.setState({
			selectedAreaPageId: pageId,
			selectedActionId: null
		});
	};

	/**
	 * Show / hide popup
	 * @param {object} popupData 
	 */
	togglePopup = (popupData = null) => {
		this.setState({popupData});
	};

	/**
	 * Show / hide dictionary
	 * @param {object} dictionaryPopupData 
	 */
	toggleDictionary = (dictionaryPopupData) => {
		this.setState({dictionaryPopupData});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading || !this.state.gameData) {
			return (
				<LoadingPage />
			);
		}

		return (
			<>
				<Game 
					animateEnterShop={this.state.animateEnterShop}
					areaId={this.state.areaId}
					selectedActionId={this.state.selectedActionId}
					selectedAreaPageId={this.state.selectedAreaPageId}
					gameData={this.state.gameData}
					popupData={this.state.popupData}
					dictionaryPopupData={this.state.dictionaryPopupData}
					handleStartGame={this.handleStartGame}
					goToArea={this.goToArea}
					selectAction={this.selectAction}
					confirmSelectedActions={this.confirmSelectedActions}
					selectPageId={this.selectPageId}
					togglePopup={this.togglePopup}
					toggleDictionary={this.toggleDictionary}
					updateGameData={this.updateGameData}
					resetGameData={this.resetGameData}
				/>
				{this.state.showStoreFront &&
					<StoreFront 
						isEndingRound={this.state.isEndingRound}
						animateEnterShop={this.state.animateEnterShop}
						newRoundNumber={this.state.gameData.roundNumber}
						handleStartGame={this.handleStartGame} 
						setCookiePopupType={this.props.setCookiePopupType} 
					/>}
			</>
		);
	};
};

GameController.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	cookiePopupType: PropTypes.string,
	setCookiePopupType: PropTypes.func.isRequired
};


export default GameController;
