import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import dayjs from 'dayjs';
import {statsUiTexts} from 'data/ui-texts';
import {getStatsSheets} from 'helpers/stats-helper';
import {
	calculateCurrentStats, 
	calculateNumberOfCustomers, 
	calculateBuyingCustomers
} from 'helpers/stat-and-budget-helpers';
import './download-stats.scss';

const DownloadStats = ({players}) => {

	/* Prepare sheets */
	let statsSheets = getStatsSheets();

	/* Get stats */
	players.forEach((player) => {
		/* Get general player stats */
		const playDate = (player && player.created ? dayjs(player.created).format('DD/MM-YY') : '-');
		const rounds = (player && player.rounds ? player.rounds : []);

		if (rounds.length === 0) return;

		const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === 'players';});
		if (sheetIndex >= 0) {
			statsSheets[sheetIndex].data.push({
				id: player.id,
				date: playDate,
				lastRound: rounds.length
			});
		}

		/* Get player stats for each round */
		rounds.forEach((round) => {
			const roundNumber = round.roundNumber ? round.roundNumber : null;
			if (roundNumber) {
				
				const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === 'round-' + roundNumber;});
				if (sheetIndex >= 0) {
					let roundData = {id: player.id};

					/* Get selected actions */
					let selectedActions = [];
					if (round.selectedActions) {
						if (Array.isArray(round.selectedActions)) {
							selectedActions = round.selectedActions;
						} else {
							for (const property in round.selectedActions) {
								selectedActions.push(
									{actionId: property, actionOptionId: round.selectedActions[property]}
								);
							}
						}
					}
					selectedActions.forEach((sa, index) => {
						roundData['action' + (index + 1)] = sa.actionOptionId;
					});
					

					/* Get stats */
					if (
						round.stats && 
						round.selectedActions && 
						Array.isArray(round.selectedActions) && 
						round.loans && 
						round.randomCustomerPercent
					) {
						const calculatedStats = calculateCurrentStats({
							stats: round.stats, 
							selectedActions: selectedActions,
							loans: round.loans,
							rounds: rounds
						});
						calculatedStats['customers'] = 
							calculateNumberOfCustomers(calculatedStats, round.randomCustomerPercent);
						calculatedStats['buying-customers'] = calculateBuyingCustomers(calculatedStats);
						roundData['salesPrice'] = calculatedStats['sales-price'];
						roundData['stock'] = calculatedStats['stock'];
						roundData['purchase'] = round.stats['purchase'];
						roundData['prodCap'] = calculatedStats['prod-capacity'];
						roundData['buyingCustomers'] = Math.round(
							(calculatedStats['customers'] * calculatedStats['buying-customers']) / 100.
						);
					}

					/* Get budget */
					if (round.actualBudget) {
						roundData['turnover'] = round.actualBudget['turnover'];
						roundData['expenses'] = round.actualBudget['total-expenses'];
						roundData['profit'] = round.actualBudget['profit'];
						roundData['liquidity'] = round.actualBudget['liquidity'];
					}

					statsSheets[sheetIndex].data.push(roundData);
				}
			}
		});
	});


	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<div className="DownloadStats">
			<ExcelFile 
				element={
					<button className="DownloadStats-btn">{statsUiTexts.download}</button>
				}
			>
				{statsSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>
		</div>
	);
};

DownloadStats.propTypes = {
	players: PropTypes.array.isRequired,
};

export default DownloadStats;