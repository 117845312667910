const budgetData = [
	[
		{
			id: 'turnover',
			dictionaryId: 'turnover',
			title: 'Omsætning',
			unit: 'kr',
			initialValue: 36000
		},
	],
	[
		{
			id: 'total-expenses',
			dictionaryId: '',
			title: 'Udgifter',
			unit: 'kr',
			initialValue: 0
		},
		{
			id: 'fixed-expenses',
			dictionaryId: 'fixed-expenses',
			title: 'Faste udgifter',
			unit: 'kr',
			initialValue: 0
		},
		{
			id: 'loans',
			dictionaryId: '',
			title: 'Renter og afdrag af lån',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'rent-and-utilities',
			dictionaryId: '',
			title: 'Husleje, el, vand og varme',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'website',
			dictionaryId: '',
			title: 'Website',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'salaries',
			dictionaryId: '',
			title: 'Løn',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'variable-expenses',
			dictionaryId: 'variable-expenses',
			title: 'Variable udgifter',
			unit: 'kr',
			initialValue: 0
		},
		{
			id: 'production',
			dictionaryId: '',
			title: 'Produktion',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'marketing',
			dictionaryId: '',
			title: 'Markedsføring',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		},
		{
			id: 'other',
			dictionaryId: '',
			title: 'Øvrige',
			unit: 'kr',
			initialValue: 0,
			isExpandedBudget: true
		}
	],
	[
		{
			id: 'profit',
			dictionaryId: 'profit',
			title: 'Overskud',
			unit: 'kr',
			initialValue: 0
		}
	],
	[
		{
			id: 'liquidity',
			dictionaryId: 'liquidity',
			title: 'Likviditet',
			unit: 'kr',
			initialValue: 0
		}
	]
];

module.exports = {
	budgetData
};