import React from 'react';
import PropTypes from 'prop-types';
import './area-pages-btn.scss';

const AreaPagesBtn = ({selectedAreaPageId, areaData, selectPageId}) => {
	
	/* Toggle area page id */
	const defaultPageId = (areaData.pages && areaData.pages.length > 0
		? selectedAreaPageId ? null : areaData.pages[0].id
		: null
	);

	return (
		<div 
			className={'AreaPagesBtn ' + areaData.id + (selectedAreaPageId ? ' active' : '')}
			onClick={() => {selectPageId(defaultPageId);}}
		>
			<div className="AreaPagesBtn-content">
				<div className="AreaPagesBtn-title">
					<span>{areaData.pagesBtnText}</span>
				</div>
				<div className={'AreaPagesBtn-imageWrapper ' + areaData.id}>
					<div className={'AreaPagesBtn-image ' + areaData.id}/>
				</div>
			</div>
		</div>
		
	);
};


AreaPagesBtn.propTypes = {
	selectedAreaPageId: PropTypes.string,
	areaData: PropTypes.object.isRequired,
	selectPageId: PropTypes.func.isRequired
};


export default AreaPagesBtn;