import React from 'react';
import PropTypes from 'prop-types';
import { gameUiTexts } from 'data/ui-texts';
import Budget from 'components/ui/budget/budget';
import './area-page-last-quarter.scss';

const AreaPageLastQuarter = ({gameData, toggleDictionary}) => {

	/* No data before round 2 */
	if (gameData.roundNumber <= 1) return null;


	/* Get predicted budget of last round */
	const prevRoundData = gameData.rounds.find((round) => {
		return round.roundNumber === gameData.roundNumber - 1;
	});
	return (
		<div className="AreaPageLastQuarter">
			<div className="AreaPageLastQuarter-predictedBudget">
				<Budget 
					type="lastQuarter"
					title={gameUiTexts.budgeted}
					gameBudget={prevRoundData.predictedBudget} 
					toggleDictionary={toggleDictionary}
				/>
			</div>
			<div className="AreaPageLastQuarter-actualBudget">
				<Budget 
					type="lastQuarter"
					title={gameUiTexts.actualBudget}
					gameBudget={prevRoundData.actualBudget} 
					toggleDictionary={toggleDictionary}
				/>
			</div>
		</div>
	);
};


AreaPageLastQuarter.propTypes = {
	gameData: PropTypes.object.isRequired,
	toggleDictionary: PropTypes.func.isRequired,
};


export default AreaPageLastQuarter;