import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({type}) => {
	

	return (
		<div className={'Logo' + (type ? ' ' + type : '')} />
	);
};


Logo.propTypes = {
	type: PropTypes.string,
};


export default Logo;