const statsData = [
	/* Constant stats (not saved in game stats object) */
	{
		id: 'rent-and-utilities',
		dictionaryId: '',
		title: 'Husleje, el, vand og varme',
		type: 'number',
		unit: 'kr',
		value: 57000
	},
	{
		id: 'website',
		dictionaryId: '',
		title: 'Website',
		type: 'number',
		unit: 'kr',
		value: 500
	},
	{
		id: 'owner-salary',
		dictionaryId: '',
		title: 'Løn til ejere',
		type: 'number',
		unit: 'kr / person',
		value: 3*15000
	},

	/* Basic stats - affected by selected actions and/or ending a round - displayed */
	{
		id: 'stock',
		dictionaryId: 'stock',
		title: 'Lager',
		type: 'number',
		unit: 'stk',
		initialValue: 400
	},
	{
		id: 'purchase-price',
		dictionaryId: 'purchase-price',
		title: 'Indkøbspris',
		type: 'number',
		unit: 'kr/stk',
		initialValue: 25
	},
	{
		id: 'production-price',
		dictionaryId: 'prod-price',
		title: 'Prod.-omkostninger',
		type: 'number',
		unit: 'kr',
		initialValue: 12
	},
	{
		id: 'marketing-effect',
		dictionaryId: '',
		title: 'Kendskab',
		type: 'range',
		minVal: 1,
		maxVal: 10,
		initialValue: 3
	},
	{
		id: 'experience',
		dictionaryId: '',
		title: 'Butiksoplevelse',
		type: 'range',
		minVal: 1,
		maxVal: 10,
		initialValue: 4
	},

	
	/* Basic stats - affected by selected actions and/or ending a round - hidden */
	{
		id: 'supplier-base',
		initialValue: 'tshirt-supplier-a',
	},
	{
		id: 'purchase-base',
		dictionaryId: '',
		title: 'Indkøb',
		type: 'number',
		unit: 'stk',
		initialValue: 200
	},
	{
		id: 'sales-price-base',
		dictionaryId: '',
		title: 'Salgspris',
		type: 'number',
		unit: 'kr',
		initialValue: 350
	},
	{
		id: 'customers-prev-round',
		dictionaryId: '',
		title: 'Kunder sidste kvartal',
		type: 'number',
		unit: null,
		initialValue: 4500
	},
	{
		id: 'prod-capacity-base',
		dictionaryId: '',
		title: 'Prod.-kapacitet',
		type: 'number',
		unit: 'stk',
		initialValue: 600
	},
	{
		id: 'quality-score-base',
		dictionaryId: '',
		title: 'Kvalitetsscore',
		type: 'number',
		unit: '',
		initialValue: 2
	},
	{
		id: 'design-score',
		dictionaryId: '',
		title: 'Designscore',
		type: 'number',
		unit: '',
		initialValue: 1
	},
	{
		id: 'number-of-owners',
		dictionaryId: '',
		title: 'Ejere',
		type: 'number',
		unit: '',
		initialValue: 3
	},
	{
		id: 'number-of-partners',
		dictionaryId: '',
		title: 'Partnere',
		type: 'number',
		unit: '',
		initialValue: 0
	},
	{
		id: 'additional-salaries',
		dictionaryId: '',
		title: 'Ekstra lønudgifter',
		type: 'number',
		unit: 'kr',
		initialValue: 0
	},
	{
		id: 'liquidity',
		dictionaryId: 'liquidity',
		title: 'Likviditet',
		type: 'number',
		unit: 'kr',
		initialValue: 30000
	},
	


	/* Basic stats - affected by selected actions and/or ending a round - hidden modifiers */
	{
		id: 'quality-score-modifier',
		dictionaryId: '',
		modifiesStatId: 'quality-score',
		type: 'add',
		initialValue: 0,
		isModifier: true
	},
	{
		id: 'prod-capacity-modifier',
		dictionaryId: '',
		modifiesStatId: 'prod-capacity',
		type: 'multiply',
		initialValue: 1,
		isModifier: true
	},
	{
		id: 'sales-price-selected',
		dictionaryId: '',
		initialValue: null,
		type: 'change',
		isModifier: true
	},
	{
		id: 'purchase-selected',
		dictionaryId: '',
		initialValue: null,
		type: 'change',
		isModifier: true
	},
	{
		id: 'supplier-selected',
		initialValue: null,
		type: 'change',
		isModifier: true
	},


	/* Calculated stats (not saved in game stats object) */
	{
		id: 'design-quality',
		dictionaryId: 'design-quality',
		title: 'Design og kvalitet',
		type: 'range',
		minVal: 1,
		maxVal: 10,
	},
	{
		id: 'customers',
		dictionaryId: 'dict-customers',
		title: 'Kunder dette kvartal',
		type: 'number',
		unit: null,
	},
	{
		id: 'buying-customers',
		dictionaryId: 'dict-buying-customers',
		title: 'Andel der køber',
		type: 'number',
		unit: '%',
	},
	{
		id: 'design-expenses',
		dictionaryId: '',
		title: 'Udgifter til design',
		type: 'number',
		unit: 'kr',
	},
	{
		id: 'quality-score',
		dictionaryId: '',
		title: 'Kvalitetsscore',
		type: 'number',
		unit: '',
	},
	{
		id: 'prod-capacity',
		dictionaryId: 'prod-capacity',
		title: 'Prod.-kapacitet',
		type: 'number',
		unit: 'stk',
	},
	{
		id: 'purchase',
		dictionaryId: '',
		title: 'Indkøb',
		type: 'number',
		unit: 'stk',
	},
	{
		id: 'sales-price',
		dictionaryId: '',
		title: 'Salgspris',
		type: 'number',
		unit: 'kr',
	},
];

module.exports = {
	statsData
};