import React from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import {checkIfActionIsAvailable} from 'helpers/game-helper';
import './area-actions.scss';

const AreaActions = ({areaId, gameData, selectedActionId, selectAction}) => {
	/* Get area data */
	const areaData = areasData.find((area) => {return area.id === areaId;});

	/* No area data / actions */
	if (!areaData || !areaData.actions || areaData.actions.length === 0) return;

	/* Select action */
	const handleSelectAction = (actionId) => {
		window.dispatchEvent(new Event('actionChosen'));
		selectAction(actionId);
	};

	return (
		<div className={'AreaActions'}>
			{areaData.actions.map((action) => {
				/* Action is locked or blocked */
				if (!checkIfActionIsAvailable(action, gameData)) return null;

				/* Check if an option for this action has been selected */
				const optionIsSelected = (gameData.selectedActions.some((sa) => {
					return sa.actionId === action.id;
				}));
				
				return (
					<div 
						key={action.id} 
						className={'AreaActions-action' + (selectedActionId === action.id ? ' open' : '')}
						onClick={() => {handleSelectAction(action.id);}} 
					>
						<div className="AreaActions-actionContent">
							<div className="AreaAction-actionTitle">
								<span>{action.title}</span>
							</div>
							<div className="AreaAction-actionStatus">
								<div className={'AreaActions-checkMark' + 
									(optionIsSelected ? ' selected' : ' unselected')}/>
							</div>
							<div className={'AreaAction-actionImageWrapper ' + action.id}>
								<div className={'AreaAction-actionImage ' + action.id} />
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};


AreaActions.propTypes = {
	areaId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	selectedActionId: PropTypes.string,
	selectAction: PropTypes.func.isRequired
};


export default AreaActions;