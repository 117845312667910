
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {dictionaryData} from 'data/dictionary-data';
import './dictionary-popup.scss';

const DictionaryPopup = ({closePopup, popupData}) => {
	useEffect(() => {
		if (popupData.id) {
			const element = document.getElementById('dict-' + popupData.id);
			if (element) {
				const parentPos = element.parentNode.getBoundingClientRect(); // parent position
				const elementPos = element.getBoundingClientRect(); // target position
				const relativePosition = elementPos.top - parentPos.top + element.parentNode.scrollTop;
				element.parentNode.scrollTo({top: relativePosition, left: 0, behavior: 'instant'});
			}
		}
	});
	return (
		<div className='DictionaryPopup' onClick={() => {closePopup();}}>
			<div className='DictionaryPopup-dialogue' onClick={(e) => {e.stopPropagation();}}>
				<div className='DicitionaryPopup-header'>
					<div className='DictionaryPopup-infoIcon'/>
					<div className='DictionaryPopup-closeButton' onClick={() => {closePopup();}}/>
				</div>
				<div id='list' className='DictionaryPopup-list'>
					{dictionaryData.map((data, index) => {
						let isSelected = false;
						if (popupData.id === data.id) {
							isSelected = true;
						}
						return (
							<div 
								key={index}
								id={'dict-' + data.id}
								className={'DictionaryPopup-listPoint' + (isSelected ? ' isSelected' : '')}
							>
								<div className='DictionaryPopup-title'>
									<span>{data.title}</span>
								</div>
								<div className='DictionaryPopup-description'>
									{data.description && renderMarkdown(data.description)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

DictionaryPopup.propTypes = {
	closePopup: PropTypes.func.isRequired,
	popupData: PropTypes.object.isRequired,
};

export default DictionaryPopup;