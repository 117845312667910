/**
 * Select a random number from a nomral distribution
 * @param {number} mean 
 * @param {number} stdev 
 * @param {number} min 
 * @param {number} max 
 * @returns 
 */
export function gaussianRandom(mean = 0, stdev = 1, min = -1, max = 1) {
	let result;
	for (let i = 0; i < 100; i++) {
		const linearRandom1 = 1 - Math.random();
		const linearRandom2 = Math.random();
		const z = Math.sqrt( -2.0 * Math.log( linearRandom1 ) ) * Math.cos( 2.0 * Math.PI * linearRandom2);
		result = z * stdev + mean;
		if (result >= min && result <= max && Math.abs(result - mean) > 1) break;
		if (i === 99) {
			result = mean - 2;
		}
	}
	return result;
}


export function addThousandSeparator(number) {
	let formattedNumber = number;
	if (number > 999 || number < -999) {
		formattedNumber = number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}
	return formattedNumber;
}