import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import './area-action-option.scss';
import resetAnimation from 'helpers/animation-helper';

const AreaActionOption = (props) => {
	const {
		canBeSelected,
		option, 
		price, 
		description, 
		isSelected, 
		isConfirmed, 
		handleSelectActionOption, 
		handleActionOptionIsNotSelectable,
		actionId
	} = props;

	/**
	 * Click on action
	 */
	const handleActionClick = () => {
		if (canBeSelected || isSelected) {
			handleSelectActionOption(option.id);
		} else {
			handleActionOptionIsNotSelectable(option);
		}

		if (!canBeSelected) {
			setShouldDisabledAnimate(true);

			resetAnimation('option-' + option.id);
		}
	};

	const [shouldDisabledAnimate, setShouldDisabledAnimate] = useState(false);

	return (
		<div 
			id={'option-' + option.id}
			key={option.id} 
			className={'AreaActionOption' + 
				(!canBeSelected ? ' disabled' : '') +
				(isSelected ? ' selected' : '') + (isConfirmed ? ' confirmed' : '') +
				(shouldDisabledAnimate ? ' disabledAnimate' : '')
			}
			onClick={() => {handleActionClick();}}
		>
			<div className="AreaActionOption-content">
				<div className={'AreaActionOption-optionImage ' + actionId + ' ' + option.id} />
				<div className="AreaActionOption-optionStatus">
					<div className={'AreaActionOption-checkMark' + 
						(isConfirmed ? ' confirmed' : ' unconfirmed')}/>
				</div>
				<div className="AreaActionOption-info">
					<div className="AreaActionOption-optionTitle">
						<span>{option.title}</span>
					</div>
					<div className="AreaActionOption-optionDescription">
						{renderMarkdown(description)}
					</div>
				</div>
				{price && <div className="AreaActionOption-price"><span>{price} {gameUiTexts.kr}</span></div>}
			</div>
		</div>
	);
};

AreaActionOption.propTypes = {
	canBeSelected: PropTypes.bool.isRequired,
	option: PropTypes.object.isRequired,
	price: PropTypes.any,
	description: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	isConfirmed: PropTypes.bool.isRequired,
	handleSelectActionOption: PropTypes.func.isRequired,
	handleActionOptionIsNotSelectable: PropTypes.func.isRequired,
	actionId: PropTypes.string.isRequired,
};

export default AreaActionOption;